import axios from 'axios'
import store from '@/store'

function setParam (params) {
  if (params.urlPdf) {
    return JSON.stringify(params);
  }
  const formData = new FormData();
  Object.entries(params)
    .forEach(([key, value]) => formData.append(key, value));
  return formData;
}

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  transformRequest: [(data = {}) => {
    // data.token = myLocalStorage.getItem('token')
    if (data.type?.toLowerCase() === '.html') {
      return JSON.stringify(data)
    }
    return setParam(data)
  }]
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(
  config => {
    store.commit('layout/START_LOADING')
    return config
  },
  error => {
    store.commit('layout/FINISH_LOADING')
    console.error(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => {
    store.commit('layout/FINISH_LOADING')
    return response
  },
  error => {
    store.commit('layout/FINISH_LOADING')
    console.error(error)
    return Promise.reject(error)
  }
)
export default () => {
  const { href } = window.location;
  const isTelelaudoHomo = href.includes('telelaudo-homo');
  const isTelelaudo = href.includes('telelaudo');

  axiosInstance.defaults.baseURL = isTelelaudoHomo
    ? 'https://editor.hmg.telelaudo.com.br'
    : isTelelaudo
      ? 'https://editor.telelaudo.com.br'
      : 'https://editor.clinux.com.br';

  return axiosInstance;
}
