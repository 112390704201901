import beforeEnter from '../utils'

export default {
  path: '/anamnese/:id',
  component: () => import('@/App.vue'),
  beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  children: [
    {
      path: '',
      component: () => import('@/pages/Layout.vue'),
      children: [
        {
          path: 'login/:cd_atendimento?/:cd_paciente?/:cd_questionario?',
          name: 'anamneseLogin',
          component: () => import('@/pages/Anamnese/Login.vue')
        },
        {
          path: '',
          name: 'anamnese',
          component: () => import('@/pages/Anamnese/Home.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'questionarios',
          name: 'questionarios',
          component: () => import('@/pages/Anamnese/Questionarios.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'perguntas',
          name: 'perguntas',
          component: () => import('@/pages/Anamnese/Perguntas.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'grupos-opcoes',
          name: 'grupos-opcoes',
          component: () => import('@/pages/Anamnese/GruposOpcoes.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'avisos',
          name: 'avisos',
          component: () => import('@/pages/Anamnese/Avisos.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'lixeira',
          name: 'lixeira',
          component: () => import('@/pages/Anamnese/Lixeira.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'responder/:atendimento?/:questionario?',
          name: 'responder',
          component: () => import('@/pages/Anamnese/Responder.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'externo/:atendimento?/:questionario?',
          name: 'externo',
          component: () => import('@/pages/Anamnese/Responder.vue')
        }
      ]
    }
  ]
}
