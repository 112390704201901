import api from '@/services/api'
import sync from '@/services/syncfusion'
import store from '@/store'
import { saveAs } from 'file-saver'
import { publicIpv4 } from 'public-ip'

export default {

  dicomWeasis (atendimento) {
    return api().post('se1/doDicomViewer', { nr_controle: atendimento.nr_controle, cd_exame: atendimento.cd_exame })
  },
  dicomWado (atendimento) {
    return api().post('se1/doWadoLink', { nr_controle: atendimento.cd_atendimento, cd_exame: atendimento.cd_exame })
  },
  dicomOsirix (atendimento) {
    return api().post('se1/doDicomOsirix', { nr_controle: atendimento.nr_controle, cd_exame: atendimento.cd_exame })
  },
  baixarRtf (exame) {
    return api().post('se1/doLaudoExportar',
      {
        cd_exame: exame
      },
      {
        responseType: 'blob',
        timeout: 10000
      })
      .then(response => {
        saveAs(response.data, `${exame}.rtf`)
      }).catch((err) => {
        err.response.data.text().then(text =>
          store.commit('layout/SET_ALERTA', { mensagem: JSON.parse(text).error || 'Error' }, { root: true })
        )
      })
  },
  /*
  baixarXml (exame) {
    return api().post('www/doDicomDownload',
      {
        cd_exame: exame
      },
      {
        responseType: 'blob',
        timeout: 10000
      })
      .then(response => {
        saveAs(response.data, `${exame}.xml`)
      })
  },
  */
  urlCgi () {
    return api().defaults.baseURL
  },

  urlPdf (exame, paciente, entrega = 'false') {
    const params = {
      cd_exame: exame,
      cd_paciente: paciente,
      cd_funcionario: store.state.login.user.cd_funcionario || 0,
      sn_entrega: entrega,
      sn_medico: store.state.login.user.sn_clinico
    }
    return publicIpv4().then(ipv4 => {
      params.ipv4 = ipv4
      const urlCgi = api().defaults.baseURL;
      const urlParams = new URLSearchParams(params).toString()
      const urlLaudo = `${urlCgi}/www/doLaudoDownload?${urlParams}`

      return api().get(urlLaudo, {
        responseType: 'blob'
      })
    }).catch((err) => {
      err.response.data.text().then(text =>
        store.commit('layout/SET_ALERTA', { mensagem: JSON.parse(text).error || 'Error' }, { root: true })
      )
    })
  },
  urlLaudoExterno (payload) {
    const params = payload;
    const urlCgi = api().defaults.baseURL;
    const urlParams = new URLSearchParams(params).toString();
    const urlLaudo = `${urlCgi}/www/doLaudoExternoDownload?${urlParams}`

    return api().get(urlLaudo, {
      responseType: 'blob'
    });
  },
  RtfToSfd (payload) {
    return sync().post('api/documenteditor/Import', {
      name: 'laudo.rtf',
      contents: new Blob([atob(payload)], { type: 'application/rtf' })
    }).catch(e => {
      console.error(e)
    })
  },

  SfdtToHtml (payload) {
    return sync().post('api/documenteditor/SfdtToHtml', {
      // name: 'laudo.html',
      filename: 'laudo.html',
      contents: payload
      // contents: new Blob([(payload)], { type: 'text/html' })
    }).catch(e => {
      console.error(e)
    })
  },

  HtmlToSfd (payload) {
    return sync().post('api/documenteditor/SystemClipboard', {
      content: (payload),
      type: '.html'
    }).catch(e => {
      console.error(e)
    })
  },
  SfdToRtf (payload) {
    return sync().post('api/documenteditor/ExportToRtf', {
      name: 'data',
      filename: 'laudo.docx',
      contents: payload
    })
  }
}
