import axios from 'axios'
import store from '@/store'

function setParam (params) {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value !== 'function') {
      formData.append(key, value);
    }
  });
  return formData;
}

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  transformRequest: [function (data) {
    return setParam(data)
  }]
}

const ApiTools = axios.create(defaultOptions)

ApiTools.interceptors.request.use(config => {
  store.commit('layout/START_LOADING')
  return config
}, error => {
  this.$store.commit('layout/FINISH_LOADING')
  console.error(error)
})

ApiTools.interceptors.response.use(response => {
  store.commit('layout/FINISH_LOADING')
  return response
}, error => {
  store.commit('layout/FINISH_LOADING')
  console.error(error)
})

export default () => {
  if (window.location.href.includes('telelaudo-homo')) {
    ApiTools.defaults.baseURL = 'https://editor.hmg.telelaudo.com.br'
  } else if (window.location.href.includes('telelaudo')) {
    ApiTools.defaults.baseURL = 'https://editor.telelaudo.com.br'
  } else {
    ApiTools.defaults.baseURL = 'https://web.clinux.com.br'
  }
  return ApiTools
}
