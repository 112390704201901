import axios from 'axios'
import store from '@/store'
import api from './api'

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000
}

function urlLogin () {
  const url = window.location.pathname.split('/')
  return `/${url[1]}/${url[2]}/login`
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(config => {
  config.baseURL = axiosInstance.defaults.baseURL = store.state.cgi.cliente.url
  if (store.getters['login/token'])
    config.headers['Authorization'] = `Bearer ${store.getters['login/token']}`
  store.commit('layout/START_LOADING')
  return config
}, error => {
  store.commit('layout/FINISH_LOADING')
  console.error(error)
})

axiosInstance.interceptors.response.use(response => {
  store.commit('layout/FINISH_LOADING')
  return response
}, async error => {
  store.commit('layout/FINISH_LOADING')
  if (error.response?.status === 401) {
    const response = await api().post('se1/getToken')
    if (response.data[0]?.token) {
      store.commit('login/auth_token', response.data[0].token)
      error.config.headers.Authorization = `Bearer ${response.data[0].token}`
      return axios(error.config)
    } else {
      store.dispatch('login/logout')
      window.open(urlLogin(), '_self')
    }
  }
  return Promise.reject(error)
})

export default () => {
  return axiosInstance
}