import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    formExame: {},
    exame: {},
    medico: {},
    listaMedico: []
  },
  getters: {
    getField
  },
  actions: {
    async abrirExame ({ commit, dispatch }, payload) {
      /*
      const objVazio = {
        cd_exame: 0,
        cd_atendimento: 0,
        cd_plano: 0,
        cd_procedimento: 0,
        nr_filme: '',
        cd_medico: '',
        cd_funcionario: 0
      }
      */
      try {
        const response = await api().post('se1/doCadastro', {
          ds_tabela: 'exames',
          cd_codigo: payload.id,
          cd_operacao: payload.op,
          js_tabela: btoa(JSON.stringify(payload.obj || {}))
        });

        if (payload.op === 3) {
          commit('SET_EXAME', response.data)
          dispatch('pesquisarMedico', { cd_medico: response.data.cd_medico })
        } else if ([0, 1].includes(payload.op)) {
          commit('SET_EXAME', payload.obj)
          commit('SET_KEY', response.data.cd_exame)
        }

        return response;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
      }
    },
    async deleteExame ({ commit }, payload) {
      try {
        return await api().post('se1/doFluxoExame', { cd_exame: payload });
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    async pesquisarMedico ({ commit }, payload) {
      const objVazio = {
        cd_medico: '',
        ds_medico: '',
        ds_crm_nr: '',
        ds_crm_uf: ''
      }
      if (!payload) {
        return objVazio
      }
      try {
        const response = await api().post('se1/doListaMedicoSol', { cd_medico: payload.cd_medico || 0, ds_medico: payload.ds_medico || '' })
        commit('SET_MEDICO', response.data[0] || objVazio)
        commit('SET_LISTA_MEDICO', response.data)
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    }
  },
  mutations: {
    updateField,
    SET_EXAME (state, payload) {
      state.exame = { ...payload }
      state.formExame = { ...payload }
    },
    SET_KEY (state, payload) {
      state.exame.cd_exame = payload
      state.formExame.cd_exame = payload
    },
    SET_LISTA_MEDICO (state, payload) {
      state.listaMedico = payload
    },
    SET_MEDICO (state, payload) {
      state.medico = payload
    }
  }
}
