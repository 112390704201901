import api from '@/services/anamnese'
import { saveAs } from 'file-saver'
import { dataURLtoBlob } from '@/mixins/utils'

export default {
  namespaced: true,
  state: {
    atendimento: 0,
    video: '',
    listaVideos: [],
    count: 0,
    dsVideo: '',
    modo: '',
    porcentagem: 0,
    formatosAceitos: 'video/mp4'
  },
  mutations: {
    DEL_LISTA (state, payload) {
      state.listaVideos.forEach((obj, index) => {
        if (obj.cd_anexo === payload.cd_anexo) {
          state.listaVideos.splice(index, 1)
        }
      })
      state.listaVideos.forEach((obj, index) => {
        if (obj.ds_arquivo === payload) {
          state.listaVideos.splice(index, 1)
        }
      })
    },
    ADD_LISTA (state, payload) {
      state.listaVideos.push(payload)
    },
    SET_VIDEOS (state, payload) {
      state.listaVideos = payload.lista
      state.atendimento = payload.atendimento
    },
    SET_COUNT (state, payload) {
      state.count = payload
    },
    SET_DS_VIDEO (state, payload) {
      state.dsVideo = payload
    },
    SET_MODO (state, payload) {
      state.modo = payload
    },
    SET_PORCENTAGEM (state, payload) {
      state.porcentagem = payload
    },
    RESET_PROGRESSO (state) {
      state.porcentagem = 0
      state.dsVideo = ''
      state.modo = ''
    },
    SET_VIDEO (state, payload) {
      state.video = payload
    }
  },
  actions: {
    abrirVideo ({ commit }, payload) {
      return api().post('se1/doAbrirVideo', payload, {
        // responseType: 'blob',
        timeout: 0,
        onDownloadProgress: (progressEvent) => {
          commit('SET_MODO', 'Download')
          commit('SET_DS_VIDEO', payload.ds_anexo)
          commit('SET_PORCENTAGEM', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }
      }).then(response => {
        commit('RESET_PROGRESSO')
        return (response.data.length) ? 'data:video/mp4;base64,' + (response.data[0].bb_anexo) : ''
      }).catch(e => {
        commit('RESET_PROGRESSO')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    listarVideo ({ commit }, payload) {
      api().post('se1/doListarVideo', { cd_exame: payload }).then(response => {
        commit('SET_VIDEOS', { lista: response.data, key: payload })
        commit('SET_COUNT', response.data?.length)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    gravarVideo ({ commit }, video) {
      return api().post('se1/doGravarVideo', video, {
        timeout: 0,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          commit('SET_DS_VIDEO', video.ds_arquivo)
          commit('SET_MODO', 'Upload')
          commit('SET_PORCENTAGEM', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }
      }).then(response => {
        video.cd_anexo = response.data.cd_anexo
        commit('ADD_LISTA', video)
        commit('RESET_PROGRESSO')
        return response
      }).catch(e => {
        commit('RESET_PROGRESSO')
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    baixarVideo (video) {
      return saveAs(dataURLtoBlob(video.bb_video), video.ds_video)
    },
    apagarVideo ({ commit }, payload) {
      return api().post('se1/doApagarVideo', payload).then(response => {
        commit('DEL_LISTA', payload)
        commit('layout/SET_ALERTA', { mensagem: response.data.Sucesso || 'Registro removido !', tipo: 'success' }, { root: true })
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  }
}
