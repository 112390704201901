import beforeEnter from './utils'
import dicomvix from './modules/dicomvix'
import clinux from './modules/clinux'
import portal from './modules/portal'
import otrs from './modules/otrs'
import solicitante from './modules/solicitante'
import entrega from './modules/entrega'
import anamnese from './modules/anamnese'
import pesquisa from './modules/pesquisa'
import orcamento from './modules/orcamento'
import paineis from './modules/paineis'

const routes = [
  otrs,
  portal,
  clinux,
  dicomvix,
  solicitante,
  entrega,
  anamnese,
  pesquisa,
  orcamento,
  paineis,
  {
    path: '/lista-cgi',
    name: 'lista-cgi',
    component: () => import('@/pages/AnaliseCgi/Home')
  },
  {
    path: '/iara/:id',
    name: 'iara',
    component: () => import('@/pages/Dicomvix/Iara.vue'),
    beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  },
  {
    path: '/login-externo/:id/:dados',
    name: 'login-externo',
    component: () => import('@/pages/LoginExterno'),
    beforeEnter: (to, from, next) => beforeEnter(to, from, next)
  },
  {
    path: '/:app/:id/app/:empresa?',
    component: () => import('@/pages/Layout.vue'),
    children: [
      {
        path: '',
        name: 'login-app',
        component: () => import('@/pages/LoginApp'),
        beforeEnter: (to, from, next) => beforeEnter(to, from, next)
      }
    ]
  },
  {
    path: '/:app/:id/mobile/:empresa?',
    component: () => import('@/pages/Layout.vue'),
    children: [
      {
        path: '',
        name: 'mobile-app',
        component: () => import('@/pages/LoginApp'),
        beforeEnter: (to, from, next) => beforeEnter(to, from, next)
      }
    ]
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('@/pages/Error404.vue')
  })
}

export default routes
