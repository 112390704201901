import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { format } from 'date-fns';
import { isDate } from '@/mixins/utils'

const filtroInicial = {
  dt_de: format(new Date(), 'dd/MM/yyyy'),
  dt_ate: '',
  cd_sala: 0,
  cd_paciente: 0
}

export default {
  namespaced: true,
  state: {
    filtro: { ...filtroInicial },
    agenda: [],
    legenda: [],
    salas: []
  },
  actions: {
    doMedicoAgenda ({ dispatch, commit, state }) {
      const filtroAtual = { ...state.filtro };
      if (!(!filtroAtual.dt_de || isDate(filtroAtual.dt_de) && (!filtroAtual.dt_ate || isDate(filtroAtual.dt_ate))))
        return
      return api().post('se1/doMedicoAgenda', state.filtro).then(response => {
        const data = response.data;

        if (data.length && !state.legenda.ds_cor_atendido) {
          dispatch('doAgendaLegenda').then(() => {
            commit('SET_AGENDA', data);
          });
        } else {
          commit('SET_AGENDA', data);
        }

        return data;
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      });
    },

    doAgendaLegenda ({ commit, state }) {
      if (state.legenda?.length) {
        return Promise.resolve([]);
      }
      return api().post('se1/doAgendaLegenda').then(response => {
        commit('SET_LEGENDA', response.data);
        return response.data;
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      });
    },

    doMedicoSala ({ commit, rootState }) {
      return api().post('se1/doMedicoSala', {
        cd_medico: rootState.login.user.cd_medico
      }).then(response => {
        commit('SET_SALAS', response.data);
        return response.data;
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      });
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_AGENDA (state, payload) {
      if (state.legenda.sn_cor_atendimento) {
        const statusToColor = {
          1: state.legenda.ds_cor_cancelado,
          2: state.legenda.ds_cor_reservado,
          3: state.legenda.ds_cor_confirmado,
          4: state.legenda.ds_cor_bloqueado,
          5: state.legenda.ds_cor_atendido,
          11: state.legenda.ds_cor_recepcao,
          14: state.legenda.ds_cor_congelado
        };
        payload.forEach(item => {
          const color = statusToColor[item.ds_status] || '000';
          item.ds_legenda = `#${color}`;
        });
      }
      state.agenda = payload;
    },
    SET_LEGENDA (state, payload) {
      state.legenda = payload[0]
    },
    SET_SALAS (state, payload) {
      state.salas = payload
    },
    RESET_FILTER (state) {
      state.filtro = { ...filtroInicial };
    }
  }
}
