import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'

export default {
  namespaced: true,
  state: {
    alerta: {
      ativo: false,
      tipo: 'success',
      mensagem: ''
    },
    loading: 0,
    modalRequest: false,
    drawer: true,
    dark: myLocalStorage.getItem('dark') === 'true' || false,
    isMobileApp: myLocalStorage.getItem('isMobile') === 'true' || false,
    snTelelaudo: false,
    snOtrs: false,
    snHomologacao: false
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    definirAlerta (state, alerta) {
      state.alerta.ativo = true
      state.alerta.mensagem = alerta.mensagem
      state.alerta.tipo = alerta.tipo
    },
    SET_ALERTA (state, alerta) {
      state.alerta.ativo = true
      state.alerta.mensagem = alerta.mensagem
      state.alerta.tipo = alerta.tipo || 'error'
      state.alerta.timeout = alerta.timeout || 3000
    },
    definirModo (state, modo) {
      state.dark = modo
      myLocalStorage.setItem('dark', modo)
    },
    definirModalRequest (state, modal) {
      state.modalRequest = modal
    },
    RESET_LOADING (state) {
      state.loading = 0
    },
    START_LOADING: state => state.loading++,
    FINISH_LOADING: state => state.loading--
  }
}
