export default {
  namespaced: true,
  state: {
    siteKey: '6LefNhEaAAAAAE68wWktV3FbSZPUKagBK_6DuFLC',
    tokenRecaptcha: '',
    eventEmit: null
  },
  actions: {
    iniciar ({ state }) {
      const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${state.siteKey}`
      const scriptTag = document.createElement('script')
      scriptTag.setAttribute('src', scriptSrc)
      scriptTag.setAttribute('id', 'scriptRecaptcha')
      document.body.appendChild(scriptTag)
    },

    validarRecaptcha ({ state, commit }, payload) {
      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.execute(state.siteKey, { action: 'submit' }).then((token) => {
          commit('SET_TOKEN_RECAPTCHA', token)
          commit('SET_EVENT_EMIT', token ? payload : null)
        })
      })
    },

    encerrar () {
      (document.querySelectorAll('.grecaptcha-badge, #scriptRecaptcha, #recaptchaContainer') || [])
        .forEach(elemento => elemento.remove())
    }
  },
  mutations: {
    SET_TOKEN_RECAPTCHA: (state, payload) => (state.tokenRecaptcha = payload),
    SET_EVENT_EMIT: (state, payload) => (state.eventEmit = payload)
  }
}
