import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    recognition: null,
    IaraConsole: '',
    ds_iara_user: '',
    ds_iara_token: '',
    cd_exame: '',
    cd_medico: '',
    iaraTexto: [],
    iaraDevices: [],
    richText: '',
  },

  getters: {
    getField
  },

  actions: {
    async doLaudoFiltroTexto ({ state, commit }, payload = null) {
      if (!payload) return null;

      try {
        const response = await api().post('se1/doLaudoFiltroTexto', {
          cd_exame: state.cd_exame,
          cd_medico: state.cd_medico,
          ds_texto: payload
        });

        return response.data?.length ? response.data[0].bb_html : undefined;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    }
  },

  mutations: {
    updateField,
    SET_IARA (state, payload) {
      state.recognition = payload
      state.iaraDevices = state.recognition.audioInputs
    },
    SET_CONSOLE (state, payload) {
      state.IaraConsole = payload
    },
    SET_TEXT (state, payload) {
      state.richText = payload
    },
    SET_PARAMS (state, payload) {
      state.cd_exame = payload.cd_exame
      state.cd_medico = payload.cd_medico
      state.ds_iara_user = payload.ds_iara_user
      state.ds_iara_token = payload.ds_iara_token
    },
  }
}
