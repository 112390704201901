import axios from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'
import ApiLaudo from '@/services/laudo'
import WPTools from '@/services/wptools'

export default {
  namespaced: true,
  state: {
    editor: null,
    editorSecundario: null,
    sfdt: myLocalStorage.getItem('sfdt'),
    selection: {},
    keyDown: {
      event: {},
      isHandled: false
    }
  },
  actions: {
    bloquearLayout ({ rootState, commit }) {
      axios().post('se1/doFuncionarioAcesso', {
        cd_funcionario: rootState.login.user.cd_funcionario,
        ds_form: 'ATE_LAUDO_EXE_CABECALHO'
      }).then(response => {
        commit('SET_BLOQUEIO', response.data.length > 0)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    keyDown ({ state, dispatch }, { event, isHandled }) {
      const key = event.key.toLowerCase();
      state.keyDown = { event, isHandled };

      const keyActions = {
        'å': () => event.altKey && dispatch('iniciarReconhecimento'),
        'a': () => event.altKey && dispatch('iniciarReconhecimento'),
        'f10': () => dispatch('iniciarReconhecimento'),
        'f3': () => dispatch('iniciarAutoTexto')
      };

      if (keyActions[key]) {
        keyActions[key]();
      }
    },

    iniciarReconhecimento ({ state, rootState }) {
      state.keyDown.event.preventDefault()
      state.keyDown.isHandled = true
      rootState.voz.inicioPorAtalho = !rootState.voz.inicioPorAtalho
    },

    iniciarAutoTexto ({ state, dispatch }) {
      state.keyDown.event.preventDefault()
      state.keyDown.isHandled = true
      // Recebe palavra selecionada
      state.editor.selection.moveToPreviousCharacter()
      state.editor.selection.selectCurrentWord()
      const texto = state.editor.selection.text
      if (texto) {
        state.editor.search.find(texto, 'WholeWord')
        dispatch('laudo/doLaudoFiltroTexto', texto, { root: true })
      }
    },

    async appendHtml ({ state }, payload) {
      // rota rtf2htm corrige erro de html do laudo estruturado
      const bb_rtf = window.btoa(payload);

      try {
        const sanitizedHtml = await WPTools().post('www/doRtf2Htm', { bb_rtf });

        if (!sanitizedHtml?.data) {
          console.warn('Rtf2Htm retornou em branco');
          return false;
        }

        const sfd = await ApiLaudo.HtmlToSfd(sanitizedHtml.data);

        state.editor.editor.paste(JSON.stringify(sfd.data));
        state.editor.selection.selectAll();
        state.editor.selection.characterFormat.fontColor = '#000000';
        state.editor.editor.selection.moveToDocumentEnd();

        return true;
      } catch (e) {
        console.error(e);
      }
    },

    async sendMask ({ commit, state }) {
      // await new Promise(resolve => setTimeout(resolve, 2000))
      console.log('editor.sendMask')
      const doc = JSON.parse(state.editor.serialize())
      doc.sections.forEach(section => {
        delete section.headersFooters;
      });
      const documento = JSON.stringify(doc);
      const original = state.editor.serialize();
      state.editor.openBlank();
      state.editor.open(documento);
      const sfdDocument = await state.editor.saveAsBlob('Docx');
      state.editor.open(original);
      state.editor.selection.selectAll();
      console.log('editor.sendMask.selection', state.editor.selection.text)
      if (state.editor.selection.text) {
        state.editor.editor.cut();
        const bodyHtml = JSON.parse(state.editor.serialize())
        commit('laudo/SET_LAYOUT', bodyHtml, { root: true });
        const response = await ApiLaudo.SfdtToHtml(sfdDocument)
        return response
      } else {
        return false
      }
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_EDITOR (state, payload) {
      state.editor = payload
    },
    SET_EDITOR_SECUNDARIO (state, payload) {
      state.editorSecundario = payload
    },
    SET_SFDT (state, payload) {
      state.sfdt = payload
    },
    /*
    SET_SELECTION (state, selection) {
      state.selection = {}
      state.selection = selection
    },
    */
    SET_BLOQUEIO (state, payload) {
      state.editor.selectionChange = payload ? () => {
        if (['HeaderText', 'FooterText', 'HeaderTableText'].includes(state.editor.selection.contextType)) {
          state.editor.selection.closeHeaderFooter()
        }
      } : () => null;
    }
  }
}
