export const myLocalStorage = {
  cliente () {
    const arrayRotas = window.location.pathname.split('/')
    return `${arrayRotas[1]}.${arrayRotas[2]}`
  },
  getItem (key) {
    return localStorage.getItem(this.cliente() + '.' + key)
  },
  setItem (key, value) {
    return localStorage.setItem(this.cliente() + '.' + key, value)
  },
  removeItem (key) {
    return localStorage.removeItem(this.cliente() + '.' + key)
  },
  clear () {
    return localStorage.clear()
  }
}
