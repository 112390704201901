export default {
  namespaced: true,
  state: {
    linkPolitica: 'https://gtecnologia.com.br/site/politica-de-privacidade-genesis-tecnologia/',
    snAceite: localStorage.getItem('termoAceite') || false
  },
  actions: {
    aceitar ({ commit }) {
      commit('SET_SN_ACEITE', true)
    }
  },
  mutations: {
    SET_SN_ACEITE (state, payload) {
      localStorage.setItem('termoAceite', payload)
      state.snAceite = payload
    }
  }
}
