import Axios from 'axios'
import saveAs from 'file-saver'
import { parse, isValid } from 'date-fns';
import mime from 'mime-types';

export function getMimeTypeFromName (filePath) {
  return mime.lookup(filePath);
}

export function tipoImagem (imagem) {
  if (imagem) {
    switch (imagem.charAt(0)) {
      case 'i':
        return 'image/png'
      case 'R':
        return 'image/gif'
      case 'U':
        return 'image/webp'
      case '/':
      default:
        return 'image/jpeg'
    }
  } else {
    return ''
  }
}

export function isDate (data) {
  const parsedDate = parse(data, 'dd/MM/yyyy', new Date());
  return isValid(parsedDate) && data.length === 10;
}

export function toReal (valor) {
  return valor ? valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }) : ''
}

export function validarCpf (cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '' || cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true;
}

export function txt2Pdf (bbTxt) {
  const url = 'https://editor.clinux.com.br/www/doTxt2Pdf';
  const formData = new FormData();
  formData.append('bb_txt', btoa(bbTxt));

  const response = Axios.post(url, formData, {
    responseType: 'blob',
    timeout: 10000
  });

  return response;
}

export function favicon (bbIcon) {
  const headElement = document.head;
  let favicon = document.querySelector("link[rel*='icon']");

  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    headElement.appendChild(favicon);
  }

  favicon.href = bbIcon;
  favicon.version = Date.now();
}

export function downloadBase64 (bbArquivo, nome, type = 'application/pdf') {
  const byteCharacters = atob(bbArquivo);
  const byteArray = new Uint8Array(byteCharacters.split('').map(char => char.charCodeAt(0)));
  const arquivo = new Blob([byteArray], { type });
  saveAs(arquivo, nome);
}

export function getMime (dataurl) {
  const match = dataurl.split(',')[0].match(/:(.*?);/);

  if (!match) {
    throw new Error('A URL de dados não contém um tipo MIME válido.');
  }

  return match[1];
}

export function blobToDataURL (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    }
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function dataURLtoBlob (dataurl) {
  const [header, base64] = dataurl.split(',');
  const mime = header.match(/:(.*?);/)[1];
  const binary = atob(base64);
  const array = Uint8Array.from(binary, char => char.charCodeAt());

  return new Blob([array], { type: mime });
}

function mod (a, b) {
  return Math.round(a - (Math.floor(a / b) * b));
}

export function geraCPF () {
  var n = 9;
  var n1 = Math.round(Math.random() * n);
  var n2 = Math.round(Math.random() * n);
  var n3 = Math.round(Math.random() * n);
  var n4 = Math.round(Math.random() * n);
  var n5 = Math.round(Math.random() * n);
  var n6 = Math.round(Math.random() * n);
  var n7 = Math.round(Math.random() * n);
  var n8 = Math.round(Math.random() * n);
  var n9 = Math.round(Math.random() * n);
  var d1 = n9 * 2 + n8 * 3 + n7 * 4 + n6 * 5 + n5 * 6 + n4 * 7 + n3 * 8 + n2 * 9 + n1 * 10;
  d1 = 11 - (mod(d1, 11));
  if (d1 >= 10) d1 = 0;
  var d2 = d1 * 2 + n9 * 3 + n8 * 4 + n7 * 5 + n6 * 6 + n5 * 7 + n4 * 8 + n3 * 9 + n2 * 10 + n1 * 11;
  d2 = 11 - (mod(d2, 11));
  if (d2 >= 10) d2 = 0;
  return '' + n1 + n2 + n3 + n4 + n5 + n6 + n7 + n8 + n9 + d1 + d2;
}

export function toBase64 (str) {
  return Buffer.from(str).toString('base64');

}

export function fromBase64 (str) {
  return Buffer.from(str, 'base64')
}

export function base64ToBlob (base64) {
  // Decodifica a string base64
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  // Converte a string binária em um array de bytes
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Cria um Blob a partir do array de bytes
  const blob = new Blob([bytes]);

  return blob;
}