import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'
import Artyom from 'artyom.js'
import validarBrowser from '@/mixins/validarBrowser'

const artyom = validarBrowser('chrome') ? new Artyom() : undefined

const inserirTexto = (state) => {
  let texto = state.texto
  texto = iniciarEmMaiusculo(texto, state.paragrafoMaiusculo)
  state.paragrafoMaiusculo = false
  texto = porX(texto)
  texto = cubicoQuadrado(texto)
  texto = dicionario(texto, state.dicionario)
  texto = replaces(texto, state)
  texto = ajusteVirgula(texto, state)

  formatarTexto(state.editor.editor, texto)
}

const dicionario = (texto, dicionario) => {
  dicionario.forEach(replace => {
    const regex = new RegExp(replace.ds_chave, 'gmi')
    texto = texto.replace(regex, replace.ds_valor)
  })

  return texto
}

const replaces = (texto, state) => {
  texto = texto.trimEnd()
  const replaces = {
    // paragrafo: [/( ?(ponto|\.){1} ?(par(a|á){1}grafos? ?)|( ?(par(a|á){1}grafos? ?)))/gmi, '.\v\v'],
    novaLinha: [/(( ?inserir linha ?)|( ?nova linha ?)|( ?\n(\r)? ?))/gmi, '\v'],
    interrogacao: [/( ?interroga(c|ç){1}(a|ã){1}o ?)/gmi, '? '],
    exclamacao: [/( ?exclama(c|ç){1}(a|ã){1}o ?)/gmi, '! '],
    ponto: [/(( ?ponto ?)|( ?\. ?))/gmi, '. '],
    paragrafo: [/( ?(par(a|á){1}grafos? ?)|( ?(par(a|á){1}grafos? ?)))/gmi, '\v\v'],
    virgula: [/(( ?v(i|í)rgula ?)|( ?, ?))/gmi, ', '],
    // autoCompletar: [/(auto(-)? ?completar)/gmi, ''],
    traco: [/((tra(c|ç)o)|(h(i|í)fen))/gmi, '-']
  }

  const matches = []
  Object.entries(replaces).forEach(replace => { matches.push(texto.matchAll(replace[1][0])) })
  // const exclusoes = ['virgula', 'autoCompletar', 'traco']
  const exclusoes = ['virgula', 'traco']
  matches.forEach(match => {
    for (const m of match) {
      const arrayFrases = texto.split(m[0])
      let fraseFinal = ''
      arrayFrases.forEach((frase, index) => {
        let fraseTemp = ''
        let ignorar = 0
        exclusoes.forEach(r => (m[0].match(replaces[r][0]) ? ignorar++ : ''))
        if (ignorar === 0) {
          fraseTemp = m[0].trim() + ` ${iniciarEmMaiusculo(frase.trimStart())}`
          state.paragrafoMaiusculo = true
        } else {
          fraseTemp = m[0].trim() + ` ${frase.trimStart()}`
        }
        fraseFinal += index === 0 ? frase : fraseTemp
      })
      texto = fraseFinal
    }
  })

  Object.entries(replaces).forEach(replace => {
    texto = texto.replace(replace[1][0], replace[1][1])
  })

  return texto.replace(/(\.{2})/gmi, '.')
    .replace(/(c\*{4})/gmi, 'corno')
}

const formatarTexto = (editor, texto) => {
  const estilos = {
    negrito: /( ?negritos? ?)/gmi,
    italico: /( ?it(a|á){1}lico ?)/gmi,
    sublinhado: /( ?sublinhado ?)/gmi
  }

  const matches = []
  Object.entries(estilos).forEach(estilo => { matches.push(texto.matchAll(estilo[1])) })
  let entradas = 0
  // Previne matches duplicados
  let matchLength = 1
  matches.forEach(match => {
    matchLength = 1
    for (const m of match) {
      if (matchLength === 1) {
        matchLength = m.length
        entradas++

        const arrayFrases = texto.split(m[0])
        arrayFrases.forEach((frase, index) => {
          if (m[0].match(estilos.negrito)) {
            frase = ` ${frase.replace(estilos.negrito, ' ')}`
            if (index === 0) {
              editor.insertText(frase)
            } else {
              editor.toggleBold()
              editor.insertText(frase)
            }
          }

          if (m[0].match(estilos.italico)) {
            frase = ` ${frase.replace(estilos.italico, ' ')}`
            if (index === 0) {
              editor.insertText(frase)
            } else {
              editor.toggleItalic()
              editor.insertText(frase)
            }
          }

          if (m[0].match(estilos.sublinhado)) {
            frase = ` ${frase.replace(estilos.sublinhado, ' ')}`
            if (index === 0) {
              editor.insertText(frase)
            } else {
              editor.toggleUnderline('Single')
              editor.insertText(frase)
            }
          }
        })
      }
    }
  })

  if (entradas === 0) {
    editor.insertText(texto)
  }
}

const iniciarEmMaiusculo = (texto, paragrafoMaiusculo) => {
  if (paragrafoMaiusculo) {
    texto = (typeof texto === 'string')
      ? texto.trimStart().charAt(0).toUpperCase() + texto.trimStart().slice(1)
      : texto
  }
  return texto
}

const porX = (texto) => {
  const matches = texto.matchAll(/([0-9.?,?] (por [0-9.?,?])+) ?(por)?/gmi)
  for (const match of matches) {
    const trecho = match[0].replace(/(por)/gmi, 'X')
    texto = texto.replace(match[0], trecho)
  }
  return texto
}

const ajusteVirgula = (texto) => {
  const matches = texto.matchAll(/([0-9], [0-9]{1,})/gmi)
  for (const match of matches) {
    const trecho = match[0].replace(' ', '')
    texto = texto.replace(match[0], trecho)
  }
  return texto
}

const cubicoQuadrado = (texto) => {
  let matches = texto.matchAll(/([0-9.?,?] ?((cm|cent(i|í)metros?) ?(c(u|ú)bicos?)))/gmi)
  for (const match of matches) {
    texto = texto.replace(match[2], 'cm³')
  }

  matches = texto.matchAll(/([0-9.?,?] ?((cm|cent(i|í)metros?) ?(quadrados?)))/gmi)
  for (const match of matches) {
    texto = texto.replace(match[2], 'cm²')
  }
  return texto
}

export default {
  namespaced: true,
  state: {
    voz: artyom?.ArtyomWebkitSpeechRecognition,
    suporteReconhecimento: artyom?.speechSupported() !== undefined,
    editor: {},
    emExecucao: false,
    inicioPorAtalho: false,
    continuar: false,
    paragrafoMaiusculo: true,
    textoTemp: '',
    texto: '',
    frases: [],
    dicionario: []
  },
  getters: {
    getField
  },
  actions: {
    comandosVoz ({ dispatch, state }) {
      artyom.addCommands([
        /*
        {
          description: 'Dicionario',
          smart: true,
          indexes: ['auto-completar *', 'autocompletar *', ' autocompletar *', 'auto completar *', ' auto completar *',
            'Auto-completar *', 'Autocompletar *', 'Auto completar *'
          ],
          action: (i, wildcard) => {
            const tx = dicionario(wildcard.trim(), state.dicionario)
            state.editor.search.findAll(state.texto.trim())
            if (state.editor.searchModule.searchResults.length > 0) {
              state.editor.search.searchResults.replaceAll(tx)
              state.editor.selection.moveToParagraphEnd()
            }
          }
        },
        */
        {
          description: 'Autotexto',
          smart: true,
          indexes: ['auto-texto *', 'autotexto *', ' autotexto *', 'auto texto *', ' auto texto *',
            'Auto-texto *', 'Autotexto *', 'Auto texto *'
          ],
          action: (i, wildcard) => {
            state.editor.search.find(state.texto.trim())
            if (state.editor.searchModule.searchResults.length > 0) {
              dispatch('laudo/doLaudoFiltroTexto', wildcard.trim(), { root: true })
            }
          }
        }
      ])
    },
    doLaudoDicionario ({ commit, rootState }) {
      api().post('se1/doLaudoDicionario', { cd_medico: rootState.login.user.cd_medico }).then(response => {
        commit('SET_DICIONARIO', response.data || [])
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  mutations: {
    updateField,
    iniciar (state, payload) {
      if (state.suporteReconhecimento) {
        const ptBr = 'pt-BR'
        artyom.ArtyomProperties.lang = ptBr
        state.emExecucao = false
        state.editor = payload
        state.continuar = true
        state.voz.lang = ptBr
        state.voz.interimResults = true
        state.voz.onresult = (event) => {
          const texto = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('')
          state.textoTemp = texto
        }
        state.voz.onend = () => {
          if (state.textoTemp !== '') {
            state.frases.push(state.textoTemp)
            state.texto = ` ${state.frases.slice(-1)[0]}`
            inserirTexto(state)
            /*
            const validarComandos = state.texto
            if (validarComandos.match(/(auto(-)? ?texto)/gmi)) {
              artyom.simulateInstruction(validarComandos)
            }
            */
            artyom.simulateInstruction(state.texto)
          }
          state.textoTemp = ''
          state.voz.stop()
          if (state.continuar) {
            state.voz.start()
          }
        }
        state.voz.onaudiostart = () => {
          state.emExecucao = true
          state.inicioPorAtalho = true
        }
        state.voz.start()
      }
    },
    encerrar (state) {
      state.continuar = false
      state.emExecucao = false
      state.inicioPorAtalho = false
      state.voz.stop()
    },
    SET_DICIONARIO (state, payload) {
      state.dicionario = payload
    }
  }
}
