import beforeEnter from '../utils'

export default {
  path: '/otrs/:id',
  component: () => import('@/App.vue'),
  beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  children: [
    {
      path: '',
      component: () => import('@/pages/Layout.vue'),
      children: [
        {
          path: 'login',
          name: 'usuario',
          component: () => import('@/pages/Otrs/Login.vue')
        },
        {
          path: '',
          name: 'otrs',
          component: () => import('@/pages/Otrs/Home.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'chamados',
          name: 'chamados',
          component: () => import('@/pages/Otrs/Chamados.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'cliente',
          name: 'cliente',
          component: () => import('@/pages/Otrs/Cliente.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'empresas',
          name: 'empresas',
          component: () => import('@/pages/Otrs/Empresas.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'funcionarios',
          name: 'funcionarios',
          component: () => import('@/pages/Otrs/Funcionarios.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'atualizacoes',
          name: 'atualizacoes',
          component: () => import('@/pages/Otrs/Atualizacoes.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
}
