import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/mixins/vuetify'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import 'normalize.css/normalize.css'
import LoadScript from 'vue-plugin-load-script'
import { registerLicense } from '@syncfusion/ej2-base'

Vue.use(LoadScript)
Vue.use(Viewer)
Vue.config.productionTip = false


// Registering Syncfusion license key
// registerLicense('Mgo+DSMBMAY9C3t2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkxgWX5adHRXTmNdVkQ=');
registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhW35Wc3dRRWFfUU0=');
// registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5VdEZiXX9fdHxTRGNV;Mgo+DSMBMAY9C3t2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5VdEZiXX9fdHxTRWhV');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
