import apiAnamnese from '@/services/anamnese'
import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import snakeCase from 'lodash/snakeCase'
import { myLocalStorage } from '@/mixins/localstore'

export default {
  namespaced: true,
  state: {
    snBase: true,
    tokenAnamnese: myLocalStorage.getItem('tokenAnamnese') || null,
    respondendo: false,
    respostas: [],
    questionario: {},
    pergunta: {},
    aviso: {},
    grupoOpcoes: {},
    opcao: {},
    atendimento: {},
    questionarios: [],
    perguntas: [],
    gruposOpcoes: [],
    inputOpcoes: [],
    // avisos: [],
    tiposInput: [],
    mascaras: [],
    cabecalhos: [],
    listaLayout: [],
    listaLixeira: [],
    itensLixeira: [],
    perguntasReferencia: [],
    perguntasRespostas: [],
    avisoOpcoes: [],
    cabecalho: {},
    assinaturas: {},
    arquivo: {}
  },
  getters: {
    getField
  },
  actions: {
    lista ({ commit }, { item, params = {} }) {
      return apiAnamnese().post(`lista/${item}`, params).then(response => {
        const data = response.data || [];
        const actions = {
          // aviso: () => commit('SET_LISTA_AVISOS', data),
          lixeira: () => commit('SET_LISTA_LIXEIRA', data),
          mascara: () => commit('SET_LISTA_MASCARAS', data),
          inputOpcao: () => commit('SET_LISTA_OPCOES', data),
          pergunta: () => commit('SET_LISTA_PERGUNTAS', data),
          cabecalho: () => commit('SET_LISTA_CABECALHOS', data),
          tipoInput: () => commit('SET_LISTA_TIPOS_INPUT', data),
          grupoOpcoes: () => commit('SET_LISTA_GRUPOS_OPCOES', data),
          questionario: () => commit('SET_LISTA_QUESTIONARIO', data),
          perguntaReferencia: () => commit('SET_PERGUNTAS_REFERENCIA', data),
          listaPerguntasRespostas: () => commit('SET_LISTA_PERGUNTAS_RESPOSTAS', data)
        };

        if (actions[item]) {
          actions[item]();
        }

        return response;
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      });
    },
    visualizar ({ commit }, { item, params = {} }) {
      return apiAnamnese().post(`visualizar/${item}`, { ...params }).then(response => {
        const data = response.data || {};
        const actions = {
          cabecalho: () => commit('SET_CABECALHO', data),
          pergunta: () => commit('SET_PERGUNTA', data)
        };

        if (actions[item]) {
          actions[item]();
        }

        return response;
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      });
    },
    validarArquivo ({ commit }, params) {
      return apiAnamnese().post('lista/arquivo', params).then(response => {
        commit('SET_ARQUIVO', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    cadastro ({ commit }, { item, params }) {
      return apiAnamnese().post(`cadastro/${item}`, params).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    desabilitar ({ commit }, { item, params }) {
      return apiAnamnese().post(`desabilitar/${item}`, params).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    lixeira ({ commit }, { endpoint, item, params }) {
      return apiAnamnese().post(`${endpoint}/${snakeCase(item)}`, params).then(response => {
        if (!response.data?.dt_criacao) {
          commit('SET_ITENS_LIXEIRA', response.data || [])
        } else {
          commit('layout/SET_ALERTA', { mensagem: `${item} restaurado com sucesso !`, tipo: 'success' }, { root: true })
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLayoutLista ({ commit }) {
      return api().post('se1/doLayoutLista').then(response => {
        commit('SET_LISTA_LAYOUT', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoLista ({ commit, state }) {
      return api().post('se1/doAnamneseExame', {
        cd_atendimento: state.atendimento.cd_atendimento
      }).then(response => {
        const atendimento = response.data[0];
        if (atendimento?.ds_paciente) {
          atendimento.ds_procedimento = response.data.map(i => i.ds_procedimento).join(', ');
          commit('SET_ATENDIMENTO', atendimento);
        } else {
          commit('SET_ATENDIMENTO', {});
          commit('layout/SET_ALERTA', { mensagem: 'Atendimento não localizado !', tipo: 'warning' }, { root: true });
        }
        return atendimento;
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      });
    },
    doLayoutAbrir ({ commit, state }) {
      api().post('se1/doLayoutAbrir', {
        cd_layout: state.questionario.cd_editor,
        cd_atendimento: state.atendimento.cd_atendimento
      }).then(response => {
        commit('SET_CABECALHO',
          response.data[0].bb_layout
            ? atob(response.data[0].bb_layout).replace(/<(style)>.*<\/style>/gmsi, '')
            : null
        )
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },

  },

  mutations: {
    updateField,
    SET_SN_BASE (state, payload) {
      state.snBase = !!payload
      myLocalStorage.setItem('tokenAnamnese', payload)
      state.tokenAnamnese = payload
    },
    SET_QUESTIONARIO (state, payload) {
      state.questionario = payload
    },
    SET_PERGUNTA (state, payload) {
      state.pergunta = payload
    },
    SET_RESPOSTAS (state, payload) {
      state.respostas = payload
    },
    SET_ORDEM (state, payload) {
      state.pergunta.nr_ordem = payload
    },
    SET_ATENDIMENTO (state, payload) {
      state.atendimento = payload
    },
    SET_PERGUNTAS_REFERENCIA (state, payload) {
      state.perguntasReferencia = payload
    },
    SET_AVISO (state, payload) {
      state.aviso = payload
    },
    SET_OPCAO (state, payload) {
      state.opcao = payload
    },
    SET_LISTA_PERGUNTAS_RESPOSTAS (state, payload) {
      state.perguntasRespostas = payload
    },
    SET_GRUPO_OPCOES (state, payload) {
      state.grupoOpcoes = payload
    },
    SET_LISTA_QUESTIONARIO (state, payload) {
      state.questionarios = payload
    },
    SET_LISTA_PERGUNTAS (state, payload) {
      state.perguntas = payload
    },
    SET_LISTA_GRUPOS_OPCOES (state, payload) {
      state.gruposOpcoes = payload
    },
    SET_LISTA_OPCOES (state, payload) {
      state.inputOpcoes = payload
    },
    /*
    SET_LISTA_AVISO_OPCOES (state, payload) {
      state.avisoOpcoes = payload
    },
    SET_LISTA_AVISOS (state, payload) {
      state.avisos = payload
    },
    */
    SET_LISTA_TIPOS_INPUT (state, payload) {
      state.tiposInput = payload
    },
    SET_LISTA_MASCARAS (state, payload) {
      state.mascaras = payload
    },
    SET_LISTA_CABECALHOS (state, payload) {
      state.cabecalhos = payload
    },
    SET_LISTA_LAYOUT (state, payload) {
      state.listaLayout = payload
    },
    SET_LISTA_LIXEIRA (state, payload) {
      state.listaLixeira = payload
    },
    SET_ITENS_LIXEIRA (state, payload) {
      state.itensLixeira = payload
    },
    SET_CABECALHO (state, payload) {
      state.cabecalho = payload
    },
    SET_ASSINATURAS (state, payload) {
      state.assinaturas = payload
    },
    SET_ARQUIVO (state, payload) {
      state.arquivo = payload
    },
    RESET (state) {
      state.respondendo = false
      state.respostas = []
      // state.questionario = {}
      state.pergunta = {}
      state.aviso = {}
      state.grupoOpcoes = {}
      state.opcao = {}
      state.atendimento = {}
      state.gruposOpcoes = []
      state.inputOpcoes = []
      state.avisos = []
      state.tiposInput = []
      state.mascaras = []
      state.perguntasReferencia = []
      state.perguntasRespostas = []
      state.cabecalho = {}
      state.assinaturas = {}
      state.arquivo = {}
    }
  }
}
