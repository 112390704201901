import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import { pt } from 'vuetify/es5/locale'
import Vuetify, { Scroll } from 'vuetify/lib'

Vue.use(Vuetify, {
  directives: {
    Scroll
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    themes: {
      light: {
        fechar: '#FFFFFF', // Branco
        success: '#4CAF50', // Verde
        danger: '#EF5350', // Vermelho
        secondary: '#455A64' // Cinza
      },
      dark: {
        fechar: '#FFFFFF',
        success: '#4CAF50',
        danger: '#EF5350',
        secondary: '#455A64'
      }
    }
  }
})