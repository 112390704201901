import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
// import { myLocalStorage } from '@/mixins/localstore'

export default {
  namespaced: true,
  state: {
    listaPedidos: [],
    pedidoFinalizado: false
  },
  actions: {
    doOrcamentoListar ({ commit }, payload) {
      return api().post('se1/doOrcamentoListar', payload).then(response => {
        commit('SET_PEDIDO_FINALIZADO', false)
        commit('SET_LISTA_PEDIDOS', response.data || [])
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doOrcamentoGravar ({ commit }, payload) {
      return api().post('se1/doOrcamentoGravar', {
        cd_pedido: payload.cd_pedido,
        cd_fornecedor: payload.cd_fornecedor,
        js_material: btoa(JSON.stringify(payload.js_material)),
        js_orcamento: btoa(JSON.stringify(payload.js_orcamento))
      }).then(response => {
        commit('SET_PEDIDO_FINALIZADO', true)
        commit('layout/SET_ALERTA', { mensagem: this.msgSuccess, tipo: 'success' }, { root: true })
        return response.data[0].cd_chamado
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,

    SET_PEDIDO_FINALIZADO (state, payload) {
      state.pedidoFinalizado = payload
    },

    SET_LISTA_PEDIDOS (state, payload) {
      state.listaPedidos = payload || []
    }
  }
}
