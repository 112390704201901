import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    exame: {},
    editForm: false
  },
  getters: {
    getField
  },
  actions: {
    iniciarRevisao ({ commit }) {
      api().post('se1/doListaNow')
        .then(response => {
          commit('SET_REVISAO_INICIO', response.data[0].now)
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
    },
    async abrirRevisao ({ commit }, payload) {

      const objVazio = {
        cd_exame: 0,
        ds_revisao_solicitante: '',
        ds_revisao_telefone: '',
        ds_revisao_email: '',
        ds_revisao_motivo: '',
        dt_revisao: '',
        cd_funcionario: 0
      }

      try {
        const response = await api().post('se1/doTeleRevisao', {
          cd_codigo: payload.id,
          cd_operacao: payload.op,
          js_tabela: btoa(JSON.stringify(payload.obj || objVazio))
        })

        commit('SET_REVISAO', response.data[0])
        return response
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    }
  },
  mutations: {
    updateField,
    SET_REVISAO (state, payload) {
      state.exame = payload
      state.editForm = false
    },
    SET_REVISAO_INICIO (state, payload) {
      state.exame.dt_revisao = payload
      state.editForm = false
    }
  }
}
