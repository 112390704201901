import store from '@/store'
import find from 'lodash/find'
import axios from 'axios'

function validaCgi (id) {
  const _options = {
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 4000
  }
  const urlOtrs = `https://lumen.clinux.com.br/chamados/cgi-bin/dwserver.cgi/se1/dotListaCgi?id=${id}`

  return axios.get(urlOtrs, _options).then((response) => {
    if (response.data[0]) {
      const clienteTemp = {}
      clienteTemp.id = response.data[0].ds_portal_id
      clienteTemp.url = response.data[0].ds_portal_url
      clienteTemp.tmp = clienteTemp.url.replace('/cgi-bin/dwserver.cgi', '')
      clienteTemp.tmp = clienteTemp.url.replace('/dwclinux', '')
      store.commit('cgi/SET_CLIENTE', clienteTemp)
      store.commit('layout/RESET_LOADING')
      return Promise.resolve(true)
    } else {
      return Promise.resolve(false)
    }
  })
}

export default function beforeEnter (to, from, next) {
  // variavel que terá dados cliente
  let clienteTemp = {}

  // pesquisa lista estatica
  clienteTemp = find(store.state.cgi.listaCliente, (c) => {
    return c.id === to.params.id
  })
  // se nao achar no config da o erro 404
  if (clienteTemp) {
    store.commit('cgi/SET_CLIENTE', clienteTemp)
    store.commit('layout/RESET_LOADING')
    next()
  } else {
    validaCgi(to.params.id).then(response => {
      if (response) {
        next()
      } else {
        next('404')
      }
    })
  }
}
