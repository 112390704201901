import api from '@/services/api'
import { format, subMonths } from 'date-fns'
import { getField, updateField } from 'vuex-map-fields'
import { toReal } from '@/mixins/utils'

const filtroInicial = {
  dt_de: format(subMonths(new Date(), 1), 'dd/MM/yyyy'),
  dt_ate: format(new Date(), 'dd/MM/yyyy'),
  sn_realizado: true
}

const totalInicial = {
  totalParticular: 0,
  totalConvenio: 0,
  total: 0
}

export default {
  namespaced: true,
  state: {
    repasse: [],
    filtro: { ...filtroInicial },
    valores: []
  },
  actions: {
    async doMedicoRepasse ({ state, commit, rootState }) {
      try {
        const filtro = { ...state.filtro, cd_medico: rootState.login.user.cd_medico }
        const response = await api().post('se1/doMedicoRepasse', filtro)
        const repasse = response.data || []
        const valores = { ...totalInicial }

        repasse.forEach(item => {
          valores.totalConvenio += item.nr_rp_convenio
          valores.totalParticular += item.nr_rp_particular
          valores.total += item.nr_rp_total
        })

        commit('SET_REPASSE', repasse)
        commit('SET_VALORES', valores)
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_REPASSE (state, payload) {
      state.repasse = payload
    },
    SET_VALORES (state, payload) {
      payload.totalConvenio = toReal(payload.totalConvenio || 'R$ 0,0')
      payload.totalParticular = toReal(payload.totalParticular || 'R$ 0,0')
      payload.total = toReal(payload.total || 'R$ 0,0')
      state.valores = [payload]
    },
    RESET (state) {
      state.filtro = { ...filtroInicial }
    }
  }
}
