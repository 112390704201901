import api from '@/services/api'

export default {
  namespaced: true,
  state: {
    listaPlano: []
  },
  getters: {

  },
  actions: {

    abrirPlano ({ commit }, payload) {

      const objVazio = {
        cd_empresa_plano: 0,
        ds_empresa_plano: '',
        cd_empresa: '',
        cd_funcionario: ''
      }

      return api().post('se1/doCadastro', {
        ds_tabela: 'empresas_planos',
        cd_codigo: payload.id,
        cd_operacao: payload.op,
        js_tabela: btoa(JSON.stringify(payload.obj || objVazio))
      }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },

    listarPlano ({ commit }, empresa) {
      api().post('se1/doListaEmpresaPlano', { cd_empresa: empresa }).then(response => {
        commit('SET_LISTA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },

  mutations: {
    SET_LISTA (state, payload) {
      state.listaPlano = payload
    }
  }
}
