import api from '@/services/api'
import ApiLaudo from '@/services/laudo'
import { getField, updateField } from 'vuex-map-fields'
// import store from '@/store'
import groupBy from 'lodash/groupBy'
import { saveAs } from 'file-saver'

export default {
  namespaced: true,
  state: {
    modalJpeg: false,
    snAvisoJava: false,
    modalAvisoJava: false,
    urlCloseWindowOsirix: 'osirix://?methodName=CloseAllWindows',
    listaJpeg: [],
    viewers: []
  },
  actions: {
    carregarViewer ({ rootState, state }) {
      const isMacOs = navigator.userAgent.match(/\(mac/gmi)
      const setup = rootState.login.setup;

      const viewersConfig = [
        { icone: 'mdi-image-area', texto: 'Mobile', acao: 'mobile', ativo: setup.sn_dicomvix_mobile },
        { icone: 'mdi-image-area', texto: 'Osirix', acao: 'osirix', ativo: isMacOs && setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'Osirix Open', acao: 'osirix-open', ativo: isMacOs && setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'Osirix Click', acao: 'osirix-onclick', ativo: isMacOs && setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Java)', acao: 'weasis', ativo: setup.sn_agenda_dicom },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Web)', acao: 'ohif', ativo: setup.sn_agenda_dicom },
        { icone: 'mdi-image-area-close', texto: 'Jpeg', acao: 'jpeg', ativo: setup.sn_agenda_imagem }
      ];

      state.viewers = viewersConfig.filter(v => v.ativo);
    },
    downloadRadiant ({ commit }, payload) {
      api().post('se1/doJsonRadiant', payload,
        {
          responseType: 'blob',
          timeout: 10000
        }).then(response => {
          saveAs(response.data, payload.cd_atendimento + '.odb')
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
    },
    async urlOhif ({ rootState, commit }, payload) {
      const setup = rootState.login.setup;
      const url = setup.ds_dicomvix_ohif || 'https://pacsviewer.clinux.com.br/local/?json=';
      const cgi = setup.sn_dicomvix_ohif ? 'doJsonDownload' : 'doDicomOhif';

      try {
        const response = await api().post('se1/doGetToken', { cd_atendimento: payload.cd_atendimento, cd_exame: payload.cd_exame });
        return `${url}${rootState.cgi.cliente.url}/se1/${cgi}?token=${response.data[0].token}`;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    async urlMobile ({ rootState, commit }, payload) {
      const url = 'https://mobile.clinux.com.br/?json=';

      try {
        const response = await api().post('se1/doGetToken', { cd_atendimento: payload.cd_atendimento, cd_exame: payload.cd_exame });
        return `${url}${rootState.cgi.cliente.url}/se1/doDicomOhif?token=${response.data[0].token}`;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },

    keyImage (payload) {
      return api().post('se1/doKeyImage', { cd_exame: payload }).then(response => {
        return response
      })
    },
    async openWado ({ state, commit, rootState }) {
      const response = await ApiLaudo.dicomWado(rootState.laudo.atendimento);
      try {
        const downloadURL = `osirix://?methodName=downloadURL&display=true&URL=${rootState.cgi.cliente.tmp}/${response.data[0]?.url}`;
        window.open(state.urlCloseWindowOsirix, '_blank');
        window.open(downloadURL, '_blank');
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },

    async clickOsirix ({ state, commit, rootState }, displayStudy) {
      const responseOsirix = await ApiLaudo.dicomOsirix(rootState.laudo.atendimento);
      if (responseOsirix.data?.length && responseOsirix.data[0].Sucesso) {
        setTimeout(() => {
          window.open(state.urlCloseWindowOsirix, '_blank');
          window.open(displayStudy + responseOsirix.data[0].Sucesso, '_blank');
        }, 5000);
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'Erro ao abrir imagem' }, { root: true });
      }
    },

    async openOsirix ({ dispatch }, displayStudy = false) {
      dispatch('openWado');
      if (displayStudy) {
        dispatch('clickOsirix', displayStudy);
      }
    },

    async openWeasis ({ rootState, commit }) {
      const response = await ApiLaudo.dicomWeasis(rootState.laudo.atendimento);
      if (response.data.error) {
        commit('layout/SET_ALERTA', { mensagem: response.data.error }, { root: true });
      } else if (response.data?.arquivo) {
        const arquivo = response.data.arquivo;
        commit('SET_MODAL_AVISO_JAVA', true);
        if (arquivo.toLowerCase().includes('http')) {
          window.open(arquivo, '_blank', 'toolbar=0,location=0,menubar=0');
        } else {
          window.open(arquivo, '_self');
        }
      }
    },

    visualizadorDicom ({ rootState, dispatch }, payload) {
      const displayStudy = 'osirix://?methodName=displayStudy&';
      const atendimento = [...new Set(rootState.dicomvix.examesDownload.map(i => i.nr_controle))].join();
      const exame = [...new Set(rootState.dicomvix.examesDownload.map(i => i.cd_exame))].join();
      switch (payload) {
        case 'jpeg':
          dispatch('doDicomSerie');
          break;
        case 'radiant':
          dispatch('downloadRadiant', { cd_atendimento: atendimento || rootState.laudo.atendimento.cd_atendimento, cd_exame: exame || rootState.laudo.atendimento.cd_exame });
          break;
        case 'ohif':
          dispatch('urlOhif', rootState.laudo.atendimento).then(response => {
            window.open(response, '_blank');
          });
          break;
        case 'weasis':
          dispatch('openWeasis');
          break;
        case 'osirix':
          dispatch('openOsirix');
          break;
        case 'mobile':
          dispatch('urlMobile', rootState.laudo.atendimento).then(response => {
            window.open(response, '_blank');
          });
          break;
        case 'osirix-open':
          dispatch('openOsirix', displayStudy);
          break;
        case 'osirix-onclick':
          dispatch('clickOsirix', displayStudy);
          break;
      }
    },
    doDicomSerie ({ commit, rootState }) {
      commit('SET_LISTA_JPEG', [])
      api().post('se1/doDicomSerie', {
        cd_atendimento: rootState.laudo.atendimento.cd_atendimento,
        nr_controle: rootState.laudo.atendimento.nr_controle,
        cd_exame: rootState.laudo.atendimento.cd_exame
      }).then(response => {
        if (response.data.error || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data.error || response.data[0]?.Falha }, { root: true })
        } else if (response.data?.length) {
          commit('SET_LISTA_JPEG', groupBy(response.data.filter(item => { return item.ds_jpg }), 'nomefilme'))
        } else {
          commit('layout/SET_ALERTA', { mensagem: 'Este exame não possui imagens Jpeg !', tipo: 'warning' }, { root: true })
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_LISTA_JPEG (state, payload) {
      state.listaJpeg = payload
      state.modalJpeg = Object.keys(payload).length
    },
    SET_MODAL_AVISO_JAVA (state) {
      if (!state.snAvisoJava) {
        state.modalAvisoJava = false
        state.snAvisoJava = true
      }
    }
  }
}
