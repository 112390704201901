import Vue from 'vue'
import Vuex from 'vuex'
// import createLogger from 'vuex/dist/logger'
// import autoload from './autoload'
import atendimentos from './modules/atendimentos'
import exames from './modules/exames'
import medicos from './modules/medicos'
import pacientes from './modules/pacientes'
import comentarios from './modules/comentarios'
import anexos from './modules/anexos'
import login from './modules/login'
import clinux from './modules/clinux'
import layout from './modules/layout'
import planos from './modules/planos'
import revisao from './modules/revisao'
import cgi from './modules/cgi'
import dicomvix from './modules/dicomvix'
import laudo from './modules/laudo'
import imagem from './modules/imagem'
import voz from './modules/voz'
import otrs from './modules/otrs'
import agendamento from './modules/agendamento'
import solicitante from './modules/solicitante'
import errosCgi from './modules/errosCgi'
import editor from './modules/editor'
import entrega from './modules/entrega'
import anamnese from './modules/anamnese'
import orcamento from './modules/orcamento'
import laudite from './modules/laudite'
import agendaMedico from './modules/agendaMedico'
import prontuario from './modules/prontuario'
import repasse from './modules/repasse'
import paineis from './modules/paineis'
import externo from './modules/externo'
import googleRecaptcha from './modules/googleRecaptcha'
import termoAceiteCookies from './modules/termoAceiteCookies'
import iara from './modules/iara'
import videos from './modules/videos'

Vue.use(Vuex)

// const debug = !process.env.NODE_ENV.startsWith('production')

const store = new Vuex.Store({
  modules: {
    cgi,
    anexos,
    atendimentos,
    comentarios,
    exames,
    clinux,
    layout,
    login,
    medicos,
    pacientes,
    planos,
    revisao,
    dicomvix,
    laudo,
    voz,
    otrs,
    agendamento,
    solicitante,
    errosCgi,
    editor,
    entrega,
    anamnese,
    orcamento,
    laudite,
    agendaMedico,
    prontuario,
    repasse,
    imagem,
    paineis,
    externo,
    googleRecaptcha,
    termoAceiteCookies,
    iara,
    videos
  }
  // enable strict mode (adds overhead!)
  // for dev mode only
  // strict: process.env.DEV
  // plugins: debug ? [createLogger()] : []
})

export default store
