import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import consts from '@/mixins/consts'

export default {
  namespaced: true,
  state: {
    formAtendimento: {},
    atendimento: {},
    sala: {},
    listaSla: [],
    listaExame: [],
    listaProcedimento: [],
    listaSala: [],
    listaUrgencia: [{ cd_urgente: true, ds_urgente: 'SIM' }, { cd_urgente: false, ds_urgente: 'NAO' }],
    listaProcedencia: [],
    listaContraste: ['NAO', 'EV', 'ORAL', 'EV E ORAL', 'RETAL']
  },
  getters: {
    getField
  },
  actions: {
    abrirAtendimento ({ commit, dispatch }, payload) {
      /*
      const objVazio = {
        cd_atendimento: 0,
        cd_paciente: '',
        nr_controle: '',
        dt_data: '',
        dt_hora: '',
        cd_sala: '',
        cd_medico: '',
        sn_urgencia: '',
        cd_procedencia: '',
        cd_sla: '',
        ds_contraste: '',
        bb_dados: '',
        ds_observacao: '',
        cd_funcionario: ''
      }
      */
      const copiaPayload = { ...payload.obj }
      const obj = { ...payload.obj }

      if ([0, 1].includes(payload.op)) {
        if (obj.bb_dados) {
          obj.bb_dados = Buffer.from(obj.bb_dados).toString('base64');
        }
      }
      return api().post('se1/doCadastro', {
        ds_tabela: 'atendimentos',
        cd_codigo: payload.id,
        cd_operacao: payload.op,
        js_tabela: btoa(JSON.stringify(obj || {}))
      }).then(response => {
        if (payload.op === 3) {
          if (response.data.bb_dados)
            response.data.bb_dados = Buffer.from(response.data.bb_dados, 'base64'); //atob(this.atendimento.bb_dados || '')
          commit('SET_ATENDIMENTO', response.data)
          dispatch('carregarSala', { sala: response.data.cd_sala })
        } else if ([0, 1].includes(payload.op)) {
          commit('SET_ATENDIMENTO', copiaPayload)
          commit('SET_KEY', response.data.cd_atendimento)
          commit('clinux/SET_ROTA', { cd_atendimento: response.data.cd_atendimento }, { root: true })
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
      })
    },
    carregarSla ({ commit, rootState }) {
      api().post('se1/doTeleSla', { cd_empresa: rootState.login.user.cd_empresa }).then(response => {
        commit('SET_LISTA_SLA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    carregarProcedencia ({ commit }) {
      api().post('se1/doListaProcedencia').then(response => {
        commit('SET_LISTA_PROCEDENCIA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    carregarExame ({ commit }, payload) {
      api().post('se1/doListaExame', { cd_atendimento: payload || 0 }).then(response => {
        commit('SET_LISTA_EXAME', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    carregarSala ({ commit, state, rootState }, payload) {
      if (state.listaSala.findIndex(i => i.cd_sala === payload.sala) < 0) {
        api().post('se1/doListaSala', { cd_empresa: rootState.login.user.cd_empresa }).then(response => {
          commit('SET_LISTA_SALA', response.data)
          commit('SET_SALA_LISTA', payload.sala)
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
      } else {
        commit('SET_SALA_LISTA', payload.sala)
      }
    },
    carregarProcedimento ({ commit }, payload) {
      if (payload.modalidade) {
        api().post('se1/doListaProcedimento', {
          cd_empresa: payload.empresa || 0,
          cd_modalidade: payload.modalidade || 0,
          cd_plano: payload.plano || 0
        }).then(response => {
          commit('SET_LISTA_PROCEDIMENTO', response.data)
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
      } else {
        commit('SET_LISTA_PROCEDIMENTO', [])
      }
    },
    fluxoFicha ({ commit }, payload) {
      return api().post('se1/doFluxoFicha', { cd_atendimento: payload.atendimento, cd_funcionario: payload.funcionario }).then(response => {
        commit('layout/SET_ALERTA', { mensagem: consts.msgPost, tipo: 'success' }, { root: true })
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      });
    },
    fluxoCancela ({ state, commit }, payload) {
      return api().post('se1/doFluxoCancela', { cd_atendimento: payload.atendimento }).then(response => {
        commit('layout/SET_ALERTA', { mensagem: consts.msgPost, tipo: 'success' }, { root: true })
        // commit('updateField', { path: 'atendimento.nr_controle', value: '' })
        // commit('updateField', { path: 'formAtendimento', value: false })
        const payload = { ...state.atendimento, nr_controle: '' }
        commit('SET_ATENDIMENTO', payload)
        return response;
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      });
    },
    fluxoDeleta ({ commit }, payload) {
      return api().post('se1/doFluxoDeleta', { cd_atendimento: payload.atendimento }).then(response => {
        commit('layout/SET_ALERTA', { mensagem: consts.msgPost, tipo: 'success' }, { root: true })
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },
  mutations: {
    updateField,
    SET_ATENDIMENTO (state, payload) {
      state.atendimento = { ...payload }
      state.formAtendimento = { ...payload }
    },
    SET_KEY (state, payload) {
      state.atendimento.cd_atendimento = payload
      state.formAtendimento.cd_atendimento = payload
    },
    SET_SALA (state, payload) {
      state.sala = payload
    },
    SET_LISTA_SLA (state, payload) {
      state.listaSla = payload
    },
    SET_LISTA_PROCEDENCIA (state, payload) {
      state.listaProcedencia = payload
    },
    SET_LISTA_SALA (state, payload) {
      state.listaSala = payload
    },
    SET_SALA_LISTA (state, payload) {
      state.sala = state.listaSala.find(i => i.cd_sala === payload)
    },
    SET_LISTA_EXAME (state, payload) {
      state.listaExame = payload
    },
    SET_LISTA_PROCEDIMENTO (state, payload) {
      state.listaProcedimento = payload
    }
  }
}
