import axios from 'axios'
import store from '@/store'

const LUMEN_API = 'https://lumen.clinux.com.br'

function urlLogin () {
  const url = window.location.pathname.split('/')
  return `/${url[1]}/${url[2]}/login`
}

function setParam (params = {}) {
  const formData = new FormData();
  Object.entries(params)
    .forEach(([key, value]) => formData.append(key, value));
  return formData;
}

function setData (config) {
  const params = config.data || {}
  const cliente = store.state.cgi.cliente.id
  params.token = store.getters['login/token'] || ''
  if (!params.token) {
    params.cd_funcionario = 1
  }
  const endpoint = `${store.state.cgi.cliente.url}/${config.url}`
  return { cliente, params, endpoint }
}

function getToken () {
  const url = store.state.cgi.cliente.url.toLowerCase()
  let data = { token: store.getters['login/token'] }
  axiosToken.defaults.baseURL = url.includes('https') || url.includes('localhost')
    ? store.state.cgi.cliente.url
    : LUMEN_API
  data = url.includes('https') || url.includes('localhost')
    ? setParam(data)
    : setData({ data, url })

  return axiosToken.post('se1/getToken', data)
}

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000
}

const axiosInstance = axios.create(defaultOptions)
const axiosToken = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(config => {
  const url = store.state.cgi.cliente.url.toLowerCase()
  config.baseURL = axiosInstance.defaults.baseURL = url.includes('https') || url.includes('localhost')
    ? store.state.cgi.cliente.url
    : LUMEN_API
  config.data = url.includes('https') || url.includes('localhost')
    ? setParam(config.data)
    : setData(config)
  if (store.getters['login/token'])
    config.headers['Authorization'] = `Bearer ${store.getters['login/token']}`
  store.commit('layout/START_LOADING')
  return config
}, error => {
  store.commit('layout/FINISH_LOADING')
  console.error(error)
})

axiosInstance.interceptors.response.use(response => {
  store.commit('layout/FINISH_LOADING')
  return response
}, error => {
  store.commit('layout/FINISH_LOADING')
  if (error.response?.status === 401) {
    store.dispatch('login/logout')
    window.open(urlLogin(), '_self')
    return
    // return getToken().then(response => {
    //   if (response.data[0]?.token) {
    //     store.commit('login/auth_token', response.data[0]?.token)
    //     const originalRequest = error.config;
    //     originalRequest.headers.Authorization = `Bearer ${response.data[0].token}`;
    //     return axios(originalRequest);
    //   } else {
    //     store.dispatch('login/logout')
    //     window.open(urlLogin(), '_self')
    //     return
    //   }
    // })
  } else {
    throw error
  }
})

export default () => {
  return axiosInstance
}