import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { toReal } from '@/mixins/utils'
//import { th } from 'vuetify/es5/locale'

const objVazio = {
  cd_paciente: 0,
  ds_paciente: '',
  dt_nascimento: '',
  ds_cpf: '',
  ds_sexo: '',
  ds_celular: '',
  ds_celular_web: '',
  ds_telefone: '',
  ds_email: '',
  ds_cep: '',
  ds_logradouro: '',
  ds_numero: '',
  ds_bairro: '',
  ds_cidade: '',
  ds_estado: '',
  cd_plano: '',
  ds_matricula: '',
  ds_mae: '',
  sn_ativo: true,
  sn_web: true,
  nr_peso: '',
  nr_altura: '',
  cd_titular: '',
  cd_funcionario: 0
}

export default {
  namespaced: true,
  state: {
    objVazio: objVazio,
    paciente: {},
    listaUF: [],
    listaSexo: ['M', 'F', 'I'],
    listaPlano: [],
    listaPaciente: [],
    listaProntuario: [],
    listaDependentes: []
  },
  getters: {
    getField
  },
  actions: {
    async abrirPaciente ({ commit }, payload) {

      const objVazio = {
        cd_paciente: '',
        ds_paciente: '',
        dt_nascimento: '',
        ds_cpf: '',
        ds_rg: '',
        ds_sexo: '',
        ds_telefone: '',
        ds_celular: '',
        ds_email: '',
        cd_empresa_plano: '',
        cd_funcionario: '',
        sn_ativo: '',
        sn_web: ''
      }

      try {
        const response = await api().post('se1/doCadastro', {
          ds_tabela: 'pacientes',
          cd_codigo: payload.id,
          cd_operacao: payload.op,
          js_tabela: btoa(JSON.stringify(payload.obj || objVazio))
        });
        commit('SET_PACIENTE', response.data);
        return response
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
      }
    },

    async novoPaciente ({ commit, state }, payload) {
      const objPaciente = { ...state.objVazio };
      try {
        const urlPaciente = (!payload.id) ? 'doPacienteTabelaNovo' : 'doPacienteTabela'

        let response = await api().post('se1/' + urlPaciente, {
          cd_paciente: payload.id,
          cd_operacao: payload.op,
          js_paciente: btoa(JSON.stringify(payload.obj || {}))
        });

        if (payload.op) {
          response.data = response.data || [objPaciente]
          if (!payload.op) {
            response.data.nr_altura = toReal(response.data.nr_altura)
            response.data.nr_peso = toReal(response.data.nr_peso)
          }
          if (!payload.cd_titular)
            commit('SET_PACIENTE', response.data)
        }
        return response
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    },
    doPacienteDependente ({ rootState, commit }) {
      return api().post('se1/doPacienteDependente', {
        cd_paciente: rootState.login.user.cd_paciente
      }).then(response => {
        if (response.data.error || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data.error || response.data[0]?.Falha }, { root: true })
        } else {
          commit('SET_LISTA_DEPENDENTES', response.data)
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    async pesquisarPaciente ({ commit }, payload) {
      const objVazio = {
        cd_paciente: '',
        ds_paciente: '',
        ds_nome: '',
        dt_nascimento: ''
      }
      if (!payload) {
        // commit('SET_PACIENTE', objVazio)
        return objVazio
      }
      /*
      const paciente = state.listaPaciente.find(p => p.cd_paciente === state.paciente.cd_paciente);
      if (paciente) {
        commit('SET_PACIENTE', paciente)
        return paciente
      }
      */
      try {
        const response = await api().post('se1/doListaPaciente', {
          cd_paciente: payload.cd_paciente || '',
          ds_paciente: payload.ds_paciente || '',
          ds_cpf: payload.ds_cpf || ''
        })
        commit('SET_LISTA', response.data)
        const pacienteData = response.data.length ? response.data[0] : objVazio
        if (!payload.readonly) {
          commit('SET_PACIENTE', pacienteData)
        }
        return pacienteData
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      }
    },
    doPacientePassword ({ commit }, payload) {
      return api().post('se1/doPacientePassword', {
        dt_nascimento: payload.dt_nascimento,
        ds_email: payload.ds_email || payload.ds_fone,
        ds_senha: payload.ds_senha
      }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    updatePassword ({ commit }, payload) {
      const objSenha = {
        cd_paciente: payload.paciente,
        ds_old: payload.antiga,
        ds_new: payload.senha
      }
      return api().post('se1/doPacientePasswordUpdate', objSenha).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    insertPassword ({ commit }, payload) {
      return api().post('se1/doPacientePasswordInsert', { cd_paciente: payload }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doListaUF ({ commit }) {
      api().post('se1/doListaUF').then(response => {
        commit('SET_UF', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doListaPlano ({ commit }) {
      return api().post('se1/doListaPlano').then(response => {
        commit('SET_PLANO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e })
      })
    },
    buscarCep ({ commit }, payload) {
      const apiCep = require('axios')
      apiCep.get(`https://viacep.com.br/ws/${payload.replace('-', '')}/json/`).then(response => {
        if (!response.data?.erro) {
          commit('SET_ENDERECO', response.data)
        }
      })
    }
  },

  mutations: {
    updateField,
    SET_PACIENTE (state, payload) {
      state.paciente = payload
    },
    SET_LISTA (state, payload) {
      state.listaPaciente = payload
    },
    SET_PLANO (state, payload) {
      state.listaPlano = payload
    },
    SET_UF (state, payload) {
      state.listaUF = []
      payload[0].UF.split(',').forEach(estado => {
        state.listaUF.push({ ds_estado: estado })
      })
    },
    SET_LISTA_DEPENDENTES (state, payload) {
      state.listaDependentes = payload || []
    },
    SET_ENDERECO (state, payload) {
      state.paciente.ds_logradouro = payload.logradouro.toUpperCase()
      state.paciente.ds_cidade = payload.localidade.toUpperCase()
      state.paciente.ds_bairro = payload.bairro.toUpperCase()
      state.paciente.ds_estado = payload.uf.toUpperCase()
    }
  }
}
