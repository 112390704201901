import axios from 'axios'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'

const apiErros = axios.create({
  baseURL: 'https://lumen.clinux.com.br/'
})

export default {
  namespaced: true,
  state: {
    lista: [],
    filtros: {},
    filtro: {
      de: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      ate: moment().format('DD/MM/YYYY'),
      cliente: null,
      status_code: null
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_LISTA (state, payload) {
      state.lista = payload
    },
    SET_FILTROS (state, payload) {
      state.filtros = payload
    }
  },
  actions: {
    carregarLista ({ commit, state, dispatch }) {
      apiErros.post('errosCgi', state.filtro).then(response => {
        commit('SET_LISTA', response.data.data)
      })
      dispatch('carregarFiltros')
    },

    carregarFiltros ({ commit }) {
      apiErros.post('errosFiltro').then(response => {
        commit('SET_FILTROS', response.data)
      })
    }
  }
}
