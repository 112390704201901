import api from "@/services/api";
import { getField, updateField } from "vuex-map-fields";
import { myLocalStorage } from "@/mixins/localstore";
import { format, subMonths } from 'date-fns';
import { isDate } from '@/mixins/utils'

const getFromLocalStorage = (key, defaultValue) => {
  const storedValue = myLocalStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

const filtroInicial = {
  cd_atendimento: 0,
  cd_paciente: "",
  dt_data: format(subMonths(new Date(), 1), 'dd/MM/yyyy'),
  dt_data_ate: format(new Date(), 'dd/MM/yyyy'),
  nr_controle: "",
  nr_filtro: 1,
  nr_data: 1,
  cd_empresa: 0,
  cd_funcionario: 0,
}

const getDefaultState = () => {
  return {
    Grid: {
      timer: null,
      Order: getFromLocalStorage("Order", ["dt_data_hora"]),
      OrderDesc: getFromLocalStorage("OrderDesc", [false]),
      Lines: getFromLocalStorage("Lines", 10),
    },
    Filtro: { ...filtroInicial },
    Rotas: getFromLocalStorage("rotas", {
      cd_atendimento: 0,
      cd_exame: 0,
      cd_paciente: 0,
      cd_laudo: 0,
    }),
    listaAtendimento: [],
    listaAlerta: [],
    listaFiltro: [],
    listaModalidade: [],
    totalChat: "",
    alerta: "",
  };
};

const state = { ...getDefaultState() };

export default {
  namespaced: true,
  state,
  getters: {
    getField,
    GetRota (state) {
      return state.Rotas;
    },
  },
  mutations: {
    updateField,
    SET_ROTA (state, payload) {
      state.Rotas = { ...payload }; // ...state.Rotas
      myLocalStorage.setItem("rotas", JSON.stringify(state.Rotas));
    },
    SET_ALERTA (state, payload) {
      state.alerta = payload;
    },
    SET_LISTA_FILTRO (state, payload) {
      state.listaFiltro = payload;
    },
    SET_LISTA (state, payload) {
      state.listaAtendimento = payload;
      state.Grid.timer = Date.now();
    },
    SET_LISTA_ALERTA (state, payload) {
      state.listaAlerta = payload;
    },
    SET_LISTA_MODALIDADE (state, payload) {
      state.listaModalidade = payload;
    },
    SET_CHAT (state, payload) {
      state.totalChat = payload;
    },
    SET_ORDER (state, payload) {
      state.Grid.Order = payload.sortBy;
      state.Grid.OrderDesc = payload.sortDesc;
      state.Grid.Lines = payload.itemsPerPage;
      myLocalStorage.setItem("Order", JSON.stringify(payload.sortBy));
      myLocalStorage.setItem("OrderDesc", JSON.stringify(payload.sortDesc));
      myLocalStorage.setItem("Lines", JSON.stringify(payload.itemsPerPage));
    },
    // SET_FILTRO (state) {
    //   myLocalStorage.setItem('filtro', JSON.stringify(state.Filtro))
    //   // myLocalStorage.setItem('grid', JSON.stringify(state.Grid))
    // },
    RESET_FILTRO (state) {
      myLocalStorage.removeItem("Order");
      myLocalStorage.removeItem("OrderDesc");
      myLocalStorage.removeItem("Lines");
      state.Grid.Order = ["dt_data_hora"];
      state.Grid.OrderDesc = [false];
      state.Grid.Lines = 10;
      state.Filtro = { ...filtroInicial };
    },
  },
  actions: {
    exibeAlertaTele ({ commit }) {
      api()
        .post("se1/doTeleAlerta")
        .then((response) => {
          commit("SET_ALERTA", response?.data[0]?.ds_alerta_paciente);
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        });
    },
    carregarAlertas ({ rootState, state, commit }) {
      const payload = {
        ...state.Filtro,
        cd_empresa: rootState.login.user.cd_empresa,
        cd_funcionario: rootState.login.user.cd_funcionario,
        sn_chat: true
      };
      api()
        .post("se1/doTeleLista", payload)
        .then((response) => {
          commit("SET_LISTA_ALERTA", response.data || []);
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        });
    },
    carregarModalidade ({ commit }, payload) {
      return api()
        .post("se1/doListaModalidade", payload)
        .then((response) => {
          commit("SET_LISTA_MODALIDADE", response.data);
          return response;
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        });
    },
    async carregarAtendimento ({ state, commit, rootState }) {
      const payload = {
        ...state.Filtro,
        cd_empresa: rootState.login.user.cd_empresa,
        cd_funcionario: rootState.login.user.cd_funcionario,
        sn_chat: false
      };
      const filtroAtual = { ...state.Filtro };
      const validaData = (!filtroAtual.dt_data || isDate(filtroAtual.dt_data) && (!filtroAtual.dt_data_ate || isDate(filtroAtual.dt_data_ate)));
      if (validaData) {
        try {
          const response = await api().post("se1/doTeleLista", payload)
          commit("SET_LISTA", response.data);
          commit("SET_ROTA", { cd_atendimento: 0, cd_paciente: 0 });
        } catch (e) {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true });
          throw e;
        }
      }
      return validaData;
    },
    carregarFiltro ({ commit }) {
      api()
        .post("se1/doTeleFiltro")
        .then((response) => {
          commit("SET_LISTA_FILTRO", response.data);
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        });
    },
  },
};
