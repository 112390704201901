import beforeEnter from '../utils'

export default {
  path: '/pesquisa/:id',
  component: () => import('@/App.vue'),
  beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  children: [
    {
      path: '',
      component: () => import('@/pages/Layout.vue'),
      children: [
        {
          path: 'login/:protocolo?/:prontuario?',
          name: 'loginPesquisa',
          component: () => import('@/pages/Pesquisa/Login.vue')
        },
        {
          path: '',
          name: 'pesquisa',
          component: () => import('@/pages/Pesquisa/Home.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
}
