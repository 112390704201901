import beforeEnter from '../utils'

export default {
  path: '/clinux/:id',
  component: () => import('@/App.vue'),
  beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  children: [
    {
      path: '',
      component: () => import('@/pages/Layout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/Clinux/Login.vue')
        },
        {
          path: '',
          name: 'clinux',
          component: () => import('@/pages/Clinux/Home.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'atendimento',
          name: 'atendimento',
          component: () => import('@/pages/Clinux/FormAtendimento.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'user',
          name: 'user',
          component: () => import('@/pages/Clinux/Usuario.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
}
