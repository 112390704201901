import beforeEnter from '../utils'

export default {
  path: '/solicitante/:id',
  component: () => import('@/App.vue'),
  beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  children: [
    {
      path: '',
      component: () => import('@/pages/Layout.vue'),
      children: [
        {
          path: '',
          name: 'sol-home',
          component: () => import('@/pages/Solicitante/Home.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'login',
          name: 'solicitante',
          component: () => import('@/pages/Solicitante/Login.vue')
        },
        {
          path: 'usuario',
          name: 'userSolicitante',
          component: () => import('@/pages/Dicomvix/Medico.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'novoSolicitante',
          name: 'novoSolicitante',
          component: () => import('@/pages/Dicomvix/Medico.vue')
        }
      ]
    }
  ]
}
