import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    grid: [],
    salas: [],
    ds_sala: '',
    ds_agora: ''
  },
  getters: {
    getField
  },
  actions: {
    doPainelMega ({ commit }, payload) {
      api().post('se1/doPainelMega', payload).then(response => {
        commit('SET_GRID_LISTA', response.data || [])
        commit('SET_SALA', response.data || [])
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doPainelMegaTma ({ commit }, payload) {
      api().post('se1/doPainelMegaTma', payload).then(response => {
        commit('SET_GRID_LISTA', response.data || [])
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaSala ({ commit }, payload) {
      api().post('se1/doListaSala', { cd_empresa: payload?.cd_empresa }).then(response => {
        commit('SET_SALA_LISTA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  mutations: {
    updateField,
    SET_GRID_LISTA (state, payload) {
      state.grid = payload
    },
    SET_SALA_LISTA (state, payload) {
      state.salas = payload
    },
    SET_SALA (state, payload) {
      state.ds_sala = payload[0]?.ds_sala
      state.ds_agora = payload[0]?.ds_agora
    }
  }
}
