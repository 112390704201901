import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    entrega: {
      ds_nome: '',
      ds_documento: '',
      nr_controle: ''
    },
    atendimentos: [],
    listaAtendimentos: [],
    valido: false,
    layout: null,
    bbPdf: null
  },
  getters: {
    getField
  },
  actions: {
    doTermoValida ({ commit, dispatch, state }) {
      api().post('se1/doTermoValida', {
        nr_controle: state.entrega.nr_controle
      }).then(response => {
        if (response.data.error || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data.error || response.data[0]?.Falha }, { root: true })
          commit('RESET')
        } else if (response.data[0]?.Sucesso) {
          dispatch('doLaudoLista')
          commit('SET_VALIDO', state.entrega.nr_controle !== null)
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },

    async doLaudoLista ({ commit, dispatch, state }) {
      try {
        const response = await api().post('se1/doLaudoLista', {
          nr_controle: state.entrega.nr_controle,
          cd_status: -1
        });

        if (response.data.length) {
          let controles = response.data.map(item => item.dt_entrega_realizado === null ? { nr_controle: item.nr_controle } : {});
          // remove nr_controles que são duplicados
          controles = controles.filter((a) => !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true), Object.create(null));
          commit('SET_LISTA_ATENDIMENTOS', controles);
          const paciente = await dispatch('pacientes/abrirPaciente', { op: 3, id: response.data[0].cd_paciente }, { root: true });
          if (paciente)
            commit('SET_ENTREGA', { ds_nome: paciente?.data?.ds_paciente, ds_documento: paciente?.data?.ds_cpf });
        } else {
          commit('SET_LISTA_ATENDIMENTOS', []);
        }
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    },

    async doTermoLayout ({ commit, state }, payload) {
      try {
        const response = await api().post('se1/doTermoLayout', {
          nr_controle: state.entrega.nr_controle
        });

        if (response.data.error || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data.error || response.data[0]?.Falha }, { root: true });
        } else if (response.data[0]?.bb_layout) {
          let layoutTemp = atob(response.data[0].bb_layout);
          if (layoutTemp.trim().match(/^<html/gmi)) {
            const lista = state.listaAtendimentos.map(item => item.nr_controle);
            layoutTemp = layoutTemp.replace('<a name="nome"></a>', `<strong>${payload.ds_nome}</strong>`)
              .replace('<a name="cpf"></a>', `<strong>${payload.ds_documento}</strong>`)
              .replace('<a name="lista"></a>', `<strong>${lista.toString()}</strong>`);
            commit('SET_LAYOUT', layoutTemp);
          } else {
            commit('layout/SET_ALERTA', { mensagem: 'O layout definido é inválido !' }, { root: true });
          }
        } else {
          commit('layout/SET_ALERTA', { mensagem: 'Layout não definido no CLinux !' }, { root: true });
        }
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    },

    async doTermoEntrega ({ commit, dispatch, state }) {
      try {
        const response = await api().post('se1/doTermoEntrega', state.entrega);

        if (response.data.error || response.data[0]?.Falha) {
          commit('RESET');
          commit('layout/SET_ALERTA', { mensagem: response.data.error || response.data[0]?.Falha }, { root: true });
        } else {
          return dispatch('doTermoGravar');
        }
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    },

    async doTermoGravar ({ commit, state }) {
      try {
        const response = await api().post('se1/doTermoGravar', {
          nr_controle: state.entrega.nr_controle,
          bb_termo: state.bbPdf
        });

        if (response.data[0]?.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Sucesso, tipo: 'success' }, { root: true });
          commit('RESET');
        } else {
          commit('layout/SET_ALERTA', {
            mensagem: response.data.error || response.data[0]?.Falha || 'Não foi possível validar o registro de entrega, tente novamente !'
          }, { root: true });
        }

        return response;
      } catch (e) {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e;
      }
    }

  },
  mutations: {
    updateField,

    SET_LISTA_ATENDIMENTOS (state, payload) {
      state.atendimentos.length = 0;
      const protocolo = payload.find(a => a.nr_controle === parseInt(state.entrega.nr_controle));
      if (protocolo?.nr_controle) {
        state.atendimentos.push(protocolo);
      }
      state.listaAtendimentos = payload;
    },

    SET_ENTREGA (state, payload) {
      state.entrega.ds_nome = payload.ds_nome
      state.entrega.ds_documento = payload.ds_documento
    },

    SET_LAYOUT (state, payload) {
      state.layout = payload
    },

    SET_VALIDO (state, payload) {
      state.valido = payload
    },

    RESET (state) {
      state.entrega = {
        ds_nome: '',
        ds_documento: '',
        nr_controle: ''
      }
      state.atendimentos = []
      state.listaAtendimentos = []
      state.valido = false
      state.layout = null
      state.bbPdf = null
    }
  }
}
