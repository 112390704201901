export default {
  msgSuccess: 'Operação realizada com sucesso !',
  msgRequired: 'Campo obrigatório não informado !',
  msgPost: 'Registro gravado com sucesso !',
  msgDelete: 'Registro removido com sucesso !',
  msgNotFound: 'Registro não encontrado !',
  msgError: 'Erro desconhecido !',
  msgBlock: 'Operação não permitida !',
  maskNumber: '##########',
  maskDate: '##/##/####',
  maskTime: '##:##',
  formatDate: 'DD/MM/YYYY',
  formatTime: 'HH:mm',
  sysSuccess: 'Sucesso',
  sysError: 'Erro',
  sysExcept: 'Falha'
}
