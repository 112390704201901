import axios from 'axios'
import { myLocalStorage } from '@/mixins/localstore'
import { getField, updateField } from 'vuex-map-fields'
import clientes from '@/services/clientes'

export default {
  namespaced: true,
  state: {
    cliente: myLocalStorage.getItem('cliente') ? JSON.parse(atob(myLocalStorage.getItem('cliente'))) : {},
    listaCliente: clientes
  },
  actions: {
    doListaCgi ({ commit }, payload = false) {
      return axios.post('https://lumen.clinux.com.br/chamados/cgi-bin/dwserver.cgi/se1/dotListaCgi')
        .then(response => {
          const lista = response.data.map(key => ({ url: key.ds_portal_url, id: key.ds_portal_id }))
          if (payload) {
            commit('SET_LISTA_ONLINE', lista)
          } else {
            commit('SET_LISTA', lista)
          }
        })
        .catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_LISTA_ONLINE (state, payload) {
      state.listaCliente = payload
    },
    SET_LISTA (state, payload) {
      state.listaCliente = state.listaCliente.concat(payload)
    },
    SET_CLIENTE (state, payload) {
      state.cliente = payload
      myLocalStorage.setItem('cliente', btoa(JSON.stringify(payload)))
    }
  }
}
