import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    listaChat: []
  },
  getters: {
    getField
  },
  actions: {

    atualizarComplemento ({ commit }, payload) {
      return api()
        .post('se1/doComplementoFim', { cd_atendimento: payload.atendimento, bb_complemento: btoa(payload.mensagem) })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        });
    },
    carregarChat ({ commit }, payload) {
      return api()
        .post('se1/doChatLista', { cd_atendimento: payload.cd_atendimento, sn_medico: payload.sn_medico })
        .then(response => {
          commit('SET_LISTA_COMENTARIO', response.data)
        }).catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        })
    },
    apagarChat ({ commit }, payload) {
      return api()
        .post('se1/doChatApagar', { cd_codigo: payload })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        })
    },
    gravarChat ({ commit }, payload) {
      const objVazio = {
        cd_atendimento: payload.atendimento,
        ds_mensagem: payload.mensagem
      }
      return api()
        .post('se1/doChatGravar', { js_tabela: btoa(JSON.stringify(objVazio)) })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e.response?.data?.error || e }, { root: true })
          throw e;
        });
    }
  },
  mutations: {
    updateField,
    SET_LISTA_COMENTARIO (state, payload) {
      state.listaChat = payload
    },
    RESET_COMENTARIO (state) {
      state.listaChat = []
    }
  }
}
