import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { format, subMonths, subDays } from 'date-fns';
import { isDate } from '@/mixins/utils'
import { myLocalStorage } from '@/mixins/localstore'

const filtroInicial = {
  cd_atendimento: '',
  cd_paciente: '',
  cd_data: 1,
  cd_fila: 0,
  cd_status: -1,
  cd_empresa: 0,
  cd_modalidade: 0,
  cd_executante: 0,
  cd_urgente: 0,
  cd_aviso: 0,
  cd_complemento: 0,
  dt_de: format(new Date(), 'dd/MM/yyyy'),
  dt_ate: '',
  nr_assinado: 2,
  nr_texto: 0,
  nr_imagem: 0,
  nr_audio: 0,
  nr_controle: '',
  flag: null,
  sn_download: false,
}

function getFromLocalStorage (key, defaultValue) {
  return myLocalStorage.getItem(key)
    ? JSON.parse(myLocalStorage.getItem(key))
    : defaultValue;
}

function postAndCommit (endpoint, commit, mutationName, payload = {}) {
  api().post(endpoint, payload).then(response => {
    commit(mutationName, response.data);
  }).catch(e => {
    commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
    throw e;
  })
}

export default {
  namespaced: true,
  state: {
    grid: [],
    dicionario: [],
    Grid: {
      gridOrder: getFromLocalStorage('gridOrder', ['ds_urgente', 'ts_entrega']),
      gridSortDesc: getFromLocalStorage('gridSortDesc', [true, false]),
      gridLines: parseInt(getFromLocalStorage('gridLines', 15))
    },
    timer: null,
    filtro: getFromLocalStorage('filtro', { ...filtroInicial }),
    examesDownload: [],
    listas: {
      status: [],
      modalidades: [],
      medicos: [],
      empresas: [],
      avisos: [],
      urgentes: [],
      complementos: [],
      filas: [],
      datas: [],
      snTodos: [{ nr: 0, ds: 'TODOS' }, { nr: 1, ds: 'SIM' }, { nr: 2, ds: 'NÃO' }]
    },
    permissao: getFromLocalStorage('permissao') || {},
  },
  getters: {
    getField
  },
  actions: {
    atualizarGrid ({ state, commit }, filtroExterno = {}) {
      const filtroAtual = { ...state.filtro, ...filtroExterno };

      if (!filtroAtual.dt_de || isDate(filtroAtual.dt_de) && (!filtroAtual.dt_ate || isDate(filtroAtual.dt_ate))) {
        api().post('se1/doLaudoLista', filtroAtual).then(response => {
          commit('SET_GRID_LISTA', response.data || []);
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
      }
    },
    carregarFiltroSimples ({ commit }, payload) {
      postAndCommit('se1/doLaudoFiltroStatus', commit, 'SET_FILTRO_STATUS');
      postAndCommit('se1/doLaudoFiltroData', commit, 'SET_FILTRO_DATA');
      postAndCommit('se1/doLaudoFiltroModalidade', commit, 'SET_FILTRO_MODALIDADE', { cd_medico: payload.cd_medico });
      postAndCommit('se1/doLaudoFiltroFila', commit, 'SET_FILTRO_FILA', { cd_medico: payload.cd_medico });
    },
    async carregarFiltroAvancado ({ commit }) {
      postAndCommit('se1/doLaudoFiltroEmpresa', commit, 'SET_FILTRO_EMPRESA');
      postAndCommit('se1/doLaudoFiltroMedico', commit, 'SET_FILTRO_MEDICO');
      postAndCommit('se1/doLaudoFiltroUrgente', commit, 'SET_FILTRO_URGENTE');
      postAndCommit('se1/doLaudoFiltroAviso', commit, 'SET_FILTRO_AVISO');
      postAndCommit('se1/doComplementoLista', commit, 'SET_FILTRO_COMPLEMENTO');
    },
    doCadastrar ({ commit }, payload) {
      const op = payload.cd_operacao
      payload.cd_operacao = undefined

      const obj = {
        ds_tabela: 'medicos_dicionario',
        cd_operacao: op,
        cd_codigo: payload.cd_codigo,
        js_tabela: btoa(JSON.stringify(payload))
      }
      return api().post('se1/doCadastrar', obj).then(response => {
        if (response.data[0]?.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: 'Adicionado com sucesso !', tipo: 'success' }, { root: true })
        } else {
          commit('layout/SET_ALERTA', { mensagem: response.data.error || response.data[0]?.Falha }, { root: true })
        }
        return true
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doDeletarDicionario ({ commit }, payload) {
      const obj = {
        ds_tabela: 'medicos_dicionario',
        cd_operacao: 2,
        cd_codigo: payload.cd_codigo
      }
      return api().post('se1/doCadastrar', obj).then(response => {
        if (response.data[0]?.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: 'Deletado com sucesso !', tipo: 'success' }, { root: true })
        } else {
          commit('layout/SET_ALERTA', { mensagem: response.data.error || response.data[0]?.Falha }, { root: true })
        }
        return true
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doMedicoDicionario ({ commit }, payload) {
      api().post('se1/doMedicoDicionario', payload)
        .then(response => {
          commit('SET_LISTA_DICIONARIO', response.data)
        })
        .catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
    },
    doLaudoPermissao ({ commit }) {
      return api().post('se1/doLaudoPermissao').then(response => {
        commit('SET_LAUDO_PERMISSAO', response.data[0])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },
  mutations: {
    updateField,
    SET_FILTRO_DATA (state, payload) {
      state.listas.datas = payload
    },
    SET_LISTA_DICIONARIO (state, payload) {
      state.dicionario = payload
    },
    SET_GRID_LISTA (state, payload) {
      state.timer = Date.now()
      state.grid = payload
    },
    SET_FILTRO_STATUS (state, payload) {
      state.listas.status = payload
    },
    SET_FILTRO_FILA (state, payload) {
      state.listas.filas = payload
    },
    SET_FILTRO_EMPRESA (state, payload) {
      state.listas.empresas = payload
    },
    SET_FILTRO_MODALIDADE (state, payload) {
      state.listas.modalidades = payload
    },
    SET_FILTRO_MEDICO (state, payload) {
      state.listas.medicos = payload
    },
    SET_FILTRO_URGENTE (state, payload) {
      state.listas.urgentes = payload
    },
    SET_FILTRO_AVISO (state, payload) {
      state.listas.avisos = payload
    },
    SET_FILTRO_COMPLEMENTO (state, payload) {
      state.listas.complementos = payload
    },
    SET_FILTRO_CONFIG (state, payload) {
      state.Grid.gridOrder = payload.sortBy
      state.Grid.gridSortDesc = payload.sortDesc
      state.Grid.gridLines = payload.itemsPerPage
    },
    SET_FILTRO (state, payload) {
      state.filtro = payload
      myLocalStorage.setItem('filtro', JSON.stringify(payload))
    },
    RESET_DOWNLOAD (state) {
      state.examesDownload = []
    },
    RESET_FILTRO (state, payload = false) {
      state.filtro = { ...filtroInicial };
      state.Grid = {
        gridOrder: ['ds_urgente', 'ts_entrega'],
        gridSortDesc: [true, false],
        gridLines: 10
      };
      if (payload) {
        // state.filtro.nr_assinado = 0;
        state.filtro.dt_de = format(subMonths(new Date(), 3), 'dd/MM/yyyy');
        state.filtro.dt_ate = format(new Date(), 'dd/MM/yyyy');
      }
      state.filtro.flag = true;
    },
    SET_PERIODO (state, payload) {
      const data = format(subDays(new Date(), payload), 'dd/MM/yyyy');
      const hoje = format(new Date(), 'dd/MM/yyyy');

      state.filtro.dt_de = data;
      switch (payload) {
        case 0:
          state.filtro.dt_de = hoje;
          state.filtro.dt_ate = '';
          break;
        case 1:
          state.filtro.dt_de = data;
          state.filtro.dt_ate = '';
          break;
        case 7:
        default:
          state.filtro.dt_de = data;
          state.filtro.dt_ate = hoje;
          break;
      }
    },
    SET_LAUDO_PERMISSAO (state, payload) {
      state.permissao = payload
      myLocalStorage.setItem('permissao', JSON.stringify(state.permissao))
    },
    REMOVE_STORAGE (state) {
      state.filtro.flag = null
      myLocalStorage.removeItem('filtro')
    }

  }
}
