import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { format, subDays } from 'date-fns'

export default {
  namespaced: true,
  state: {
    filtro: {
      dt_de: format(subDays(new Date(), 30), 'dd/MM/yyyy'),
      dt_ate: format(new Date(), 'dd/MM/yyyy'),
      cd_paciente: 0,
      ds_paciente: ''
    },
    listaAtendimento: []
  },
  getters: {
    getField
  },
  actions: {
    doListaSolicitante ({ commit, state, rootState }) {
      state.filtro.cd_medico = rootState.login.user.cd_medico
      api().post('se1/doListaSolicitante', state.filtro).then(response => {
        commit('SET_LISTA_ATENDIMENTO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },
  mutations: {
    updateField,
    SET_LISTA_ATENDIMENTO (state, payload) {
      state.listaAtendimento = payload
    }
  }
}
