import beforeEnter from '../utils'

export default {
  path: '/portal/:id',
  component: () => import('@/App.vue'),
  beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  children: [
    {
      path: '',
      component: () => import('@/pages/Layout.vue'),
      children: [
        {
          path: 'login',
          name: 'paciente',
          component: () => import('@/pages/Portal/Login.vue')
        },
        {
          path: 'resultados/:protocolo?/:prontuario?',
          name: 'resultados',
          component: () => import('@/pages/Portal/LoginProtocolo.vue')
        },
        {
          path: 'registrar',
          name: 'cadastroPaciente',
          component: () => import('@/pages/Portal/Paciente.vue')
        },
        {
          path: 'recuperar/:paciente/:token',
          name: 'recuperarSenha',
          component: () => import('@/pages/Portal/RedefinirSenha.vue')
        },
        {
          path: '',
          name: 'portal',
          component: () => import('@/pages/Portal/Home.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'agendamento',
          name: 'agendamento',
          component: () => import('@/pages/Portal/Agenda.vue'),
          meta: {
            requiresAuth: true
          },
          props: true
        },
        {
          path: 'sucesso',
          name: 'sucesso',
          component: () => import('@/pages/Portal/Sucesso.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'consulta',
          name: 'consulta',
          component: () => import('@/pages/Portal/Consultorio.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'consultorio',
          name: 'consultorio',
          component: () => import('@/pages/Portal/Consultorio.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'cadastro',
          name: 'editarPaciente',
          component: () => import('@/pages/Portal/Paciente.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'dependentes',
          name: 'dependentes',
          component: () => import('@/pages/Portal/Dependentes.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
}
