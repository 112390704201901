import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { blobToDataURL, base64ToBlob } from '@/mixins/utils'

export default {
  namespaced: true,
  state: {
    medico: {},
    listaUF: [],
    listaRepasse: [],
    foto: ''
  },
  getters: {
    getField
  },
  actions: {
    abrirMedico ({ commit }, payload) {
      // const objVazio = Object.assign({}, this.objVazio)

      const objVazio = {
        cd_medico: '',
        ds_medico: '',
        ds_cpf: '',
        ds_crm_nr: '',
        ds_crm_uf: '',
        ds_email: '',
        sn_ativo: true,
        sn_clinico: false,
        ds_especialidade: '',
        ds_senha: '',
        cd_usuario: '',
        cd_funcionario: ''
      }

      const endpoint = payload.id ? 'se1/doCadastro' : 'se1/doCadastroSolicitante'
      return api().post(endpoint, {
        ds_tabela: 'medicos',
        cd_codigo: payload.id,
        cd_operacao: payload.op,
        js_tabela: btoa(JSON.stringify(payload.obj || objVazio))
      }).then(response => {
        commit('SET_MEDICO', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doListaUF ({ commit }) {
      api().post('se1/doListaUF').then(response => {
        commit('SET_UF', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doLaudoMedico ({ commit }, payload) {
      return api()
        .post('se1/doLaudoMedico', { cd_atendimento: payload.cd_atendimento, cd_medico: payload.cd_medico })
        .catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
    },
    doLaudoRevisor ({ commit }, payload) {
      return api()
        .post('se1/doLaudoRevisor', { cd_atendimento: payload.cd_atendimento, cd_medico: payload.cd_medico })
        .catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        })
    },
    doMedicoRepasse ({ commit }, payload) {
      api().post('se1/doMedicoRepasse', payload).then(response => {
        commit('SET_REPASSE', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },

    async doMedicoFoto ({ commit }, payload) {
      if (payload) {
        try {
          const response = await api().post('se1/doMedicoFoto', { cd_medico: payload })
          let bbFoto = base64ToBlob(response.data[0]?.bb_foto)
          bbFoto = bbFoto ? await blobToDataURL(bbFoto) : null
          commit('SET_FOTO', bbFoto)
        } catch (e) {
          commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
          throw e
        }
      } else {
        commit('SET_FOTO', null)
      }
    },
    doMedicoNewPassword ({ commit }, payload) {
      return api().post('se1/doMedicoNewPassword', payload).then(response => {
        commit('layout/SET_ALERTA', { mensagem: this.msgSuccess, tipo: 'success' }, { root: true })
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    },
    doMedicoPassword ({ commit }, payload) {
      api().post('se1/doMedicoPassword', { ds_email: payload }).then(response => {
        commit('layout/SET_ALERTA', { mensagem: this.msgSuccess, tipo: 'success' }, { root: true })
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e.response?.data?.error || e }, { root: true })
        throw e
      })
    }
  },
  mutations: {
    updateField,
    SET_UF (state, payload) {
      state.listaUF = payload[0].UF.split(',').map(estado => ({ ds_estado: estado }));
    },
    SET_MEDICO (state, payload) {
      state.medico = payload
    },
    SET_REPASSE (state, payload) {
      state.listaRepasse = payload
    },
    SET_FOTO (state, payload) {
      state.foto = payload
    }
  }
}
