import beforeEnter from '../utils'

export default {
  path: '/entrega/:id',
  component: () => import('@/App.vue'),
  beforeEnter: (to, from, next) => beforeEnter(to, from, next),
  children: [
    {
      path: '',
      component: () => import('@/pages/Layout.vue'),
      children: [
        {
          path: 'login',
          name: 'entrega',
          component: () => import('@/pages/Entrega/Login.vue')
        },
        {
          path: '',
          name: 'home-entrega',
          component: () => import('@/pages/Entrega/Home.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
}
